import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const NumberOfEmployeesPage = () => {
  const { quizData, updateQuizData } = useContext(QuizContext);
  const [numberOfEmployees, setNumberOfEmployees] = useState(quizData.numberOfEmployees? quizData.numberOfEmployees : '');
  const navigate = useNavigate()
  const [totalQuestions, setTotalQuestions] = useState(12);
  const currentQuestion = 2; // This should be dynamically updated based on the current question index

  const handleNextClick = () => {
    if (numberOfEmployees) {
      updateQuizData({ numberOfEmployees });
      navigate('/quiz/revenue');
    }
  };

  const handleBackClick = () => {
    navigate('/quiz/time-period');
  }

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
          <div className="progress-wrapper sticky-top mb-3">
                  <div className="d-flex justify-content-between mb-1">
                    <span>{currentQuestion} / {totalQuestions}</span>
                  </div>
                  <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                </div>
            <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <Row className="align-items-center">
              <Col lg={6}>

                <h2>How many people worked at your company at the end of this time period?</h2>
                <p className="text-muted fs-17">Tip: Your HR or management team would know this</p>
                <input
                  type="number"
                  value={numberOfEmployees}
                  onChange={(e) => setNumberOfEmployees(e.target.value)}
                  className="form-control"
                  placeholder="Number of Employees"
                />
                <div className="mt-2">
                <button onClick={handleBackClick} className="btn btn-primary me-4">
                  Back
                </button>
                <button onClick={handleNextClick} disabled={!numberOfEmployees} className="btn btn-primary">
                  Next
                </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="me-4">
                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                <p className="text-muted fs-17">
                  We need the total headcount of your company at the end of year to understand your size.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default NumberOfEmployeesPage;
