import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Badge, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserContext from "../../contexts/user/UserContext";
import { faEye, faEyeSlash, faCircleInfo, faFileCode } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/developers.css';
import http from '../../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../../frameworks/basic-rest/api-endpoints';
import { ToastContainer, toast } from 'react-toastify';
import { ModeContext } from '../../contexts/mode/ModeContext.js'; // Import the context
import { useNavigate, useOutletContext } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";

const DevelopersPage = () => {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [apiKeys, setApiKeys] = useState([]);
    const [paymentSetup, setPaymentSetup] = useState(false);
    const [showKey, setShowKey] = useState(null); // Track which key is being shown
    const [tooltipOpen, setTooltipOpen] = useState(false); // Track tooltip state
    const [sandboxMode, setSandboxMode] = useState(true);
    const { isSandboxMode, setIsSandboxMode } = useContext(ModeContext); // Use the context
    const [hasActiveKey, setHasActiveKey] = useState(false);
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(false);
    const [modal, setModal] = useState(false); // Modal state
    const [selectedKey, setSelectedKey] = useState(null); // Selected API key for revoke

    useEffect(() => {
        const fetchApiKeys = async () => {
            try {
                const endpoint = isSandboxMode ? API_ENDPOINTS.GET_SANDBOX_API_KEYS : API_ENDPOINTS.GET_LIVE_API_KEYS;
                const response = await http.get(endpoint);
                setApiKeys(response.data.apiKeys);
                setHasActiveKey(response.data.apiKeys.some(key => key.status === 'active'));

            } catch (error) {
                console.error('Error fetching API keys:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchPaymentSetup = async () => {
            try {
                const response = await http.post(API_ENDPOINTS.COMPANY);
                setPaymentSetup(response.data.paymentSetup);
            } catch (error) {
                console.error('Error fetching API keys:', error);
            }
        }

        fetchApiKeys();
        fetchPaymentSetup();
    }, [isSandboxMode, refresh, paymentSetup]);

    const handleAPIDocsClick = () => {
        window.open("https://c-mass.mintlify.app/introduction", "_blank");
    };

    const handleCreateSandboxApiKey = async () => {
        setLoading(true);
        try {
            const response = await http.post(API_ENDPOINTS.CREATE_APIKEY, { type: 'sandbox' });

            if (response.status === 201) {
                toast.success('Sandbox API Key Created!');
                setApiKeys([...apiKeys, response.data]);
                setRefresh(!refresh);
            }
        } catch (error) {
            console.error('Error creating sandbox API key:', error);
            alert('Failed to create sandbox API key');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateLiveApiKey = async () => {
        if (paymentSetup) {
            setLoading(true);
            try {
                const response = await http.post(API_ENDPOINTS.CREATE_APIKEY, { type: 'production' });

                if (response.status === 201) {
                    toast.success('Live API Key Created!');
                    setApiKeys([...apiKeys, response.data]);
                    setRefresh(!refresh);
                }
            } catch (error) {
                console.error('Error creating live API key:', error);
                alert('Failed to create live API key');
            }
            finally {
                setLoading(false);
            }
        } else {
            toast.error('Set up API billing to create a live API key');
        }

    };

    const handleSetUpBilling = () => {
        navigate('/profile/setup-payment')
    }


    const toggleShowKey = (index) => {
        if (showKey === index) {
            setShowKey(null); // Hide the key if it's already shown
        } else {
            setShowKey(index); // Show the specific key
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('API key copied to clipboard!');
    };

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen); // Toggle the tooltip state

    const toggleSandboxMode = () => setIsSandboxMode(!isSandboxMode);

    const toggleModal = () => setModal(!modal); // Toggle the modal visibility

    const revokeApiKey = async (apiKey) => {
        try {
            // Attempt to revoke the API key
            await http.put(API_ENDPOINTS.REVOKE_SANDBOX_APIKEY, { apiKey: apiKey.key });

            // If the revocation was successful, show success toast
            toast.success('API Key has been revoked successfully.');

            // Close the modal after revocation
            setModal(false);

            // Refresh the list of API keys
            setRefresh(!refresh);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(`Error revoking API key: ${error.response.data.message}`);
            } else {
                toast.error('Failed to revoke API key. Please try again.');
            }
        }
    };

    const confirmRevoke = (key) => {
        setSelectedKey(key); // Set the selected key to be revoked
        toggleModal(); // Show the modal
    };

    const currentApiKey = isSandboxMode ? apiKeys.find(key => key.type === 'sandbox') : apiKeys.find(key => key.type === 'production');

    // Check if any API key is active

    return (
        <Container className="dashboard">
            <Row className="mb-4">
                <Col>
                    <h1 style={{ fontWeight: 'bold' }}>Developers</h1>
                </Col>
                <Col>
                    <Button outline color="dark" className="rotate-button" onClick={handleAPIDocsClick} style={{ borderRadius: '30px', padding: '6px 14px' }}><FontAwesomeIcon icon={faFileCode} /> API Docs</Button>
                </Col>
            </Row>

            {/* Display message if no active keys */}

            {loading ? <Spinner /> : <>
                <Row>
                    <Col>
                        <Card className="p-3 mb-4" style={{ borderRadius: '15px', borderColor: 'white' }}>
                            <Row>
                                <Col md={8}>
                                    <p>{isSandboxMode ? 'You are viewing your sandbox API keys. Toggle to view live keys.' : 'You are viewing your live API keys. Toggle to view sandbox keys.'}</p>
                                </Col>
                                <Col md={4} className="text-right">
                                    <div className="toggle-container">
                                        <span className="toggle-text">Sandbox mode</span>
                                        <div
                                            className={`custom-toggle ${isSandboxMode ? 'active' : ''}`}
                                            onClick={toggleSandboxMode}
                                        >
                                            <div className="slider"></div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card className="p-4" style={{ borderRadius: '15px', borderColor: 'white' }}>
                            {/* Display message if no active sandbox keys */}
                            {!hasActiveKey && isSandboxMode && (
                                <Card className="p-3 mb-4" style={{ backgroundColor: '#e4edf4', borderRadius: '10px' }}>
                                    <Row>
                                        <Col md={8}>
                                            <span>
                                                <FontAwesomeIcon icon={faCircleInfo} style={{ color: "#1682d4" }} />
                                                <span style={{ marginLeft: '10px' }}>
                                                    You have no active sandbox API keys. Create one to continue making API calls.
                                                </span>
                                            </span>
                                        </Col>
                                        <Col md={4} className="text-right">
                                            <Button
                                                outline
                                                color="primary"
                                                style={{ borderRadius: '25px', padding: '5px 15px' }}
                                                onClick={handleCreateSandboxApiKey}
                                            >
                                                Create sandbox API key
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )}

                            {/* Display message if no active live keys or payment not set up */}
                            {!isSandboxMode && (!hasActiveKey || !paymentSetup) && (
                                <Card className="p-3 mb-4" style={{ backgroundColor: '#e4edf4', borderRadius: '10px' }}>
                                    <Row>
                                        <Col md={8}>
                                            <span>
                                                <FontAwesomeIcon icon={faCircleInfo} style={{ color: "#1682d4" }} />
                                                <span style={{ marginLeft: '10px' }}>
                                                    {!hasActiveKey
                                                        ? 'You have no active live API keys. '
                                                        : 'Set up billing to create a live API key. '}
                                                    {paymentSetup
                                                        ? 'Create one to continue.'
                                                        : 'Set up billing to create a live API key.'}
                                                </span>
                                            </span>
                                        </Col>
                                        <Col md={4} className="text-right">
                                            <Button
                                                outline
                                                color="primary"
                                                style={{ borderRadius: '25px', padding: '5px 15px' }}
                                                onClick={paymentSetup ? handleCreateLiveApiKey : handleSetUpBilling}
                                            >
                                                {paymentSetup ? 'Create live API key' : 'Set up billing'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )}

                            {(paymentSetup || isSandboxMode) && currentApiKey && (
                                <Container className="mt-5">
                                    <h2>API Keys</h2>
                                    <table className="api-keys-table">
                                        <thead>
                                            <tr>
                                                <th>API KEY</th>
                                                <th>STATUS</th>
                                                <th>CREATED ON</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {apiKeys.map((key, index) => (
                                                <tr key={index}>
                                                    <td className="api-key">
                                                        <span
                                                            id={`apiKey-${index}`}
                                                            onClick={() => copyToClipboard(key.key)}
                                                            onMouseEnter={toggleTooltip}
                                                            onMouseLeave={toggleTooltip}
                                                            style={{ cursor: showKey === index ? 'pointer' : 'default' }}
                                                        >
                                                            {showKey === index ? key.key : `${key.key?.slice(0, 15)}...`}
                                                        </span>
                                                        <Tooltip isOpen={tooltipOpen && showKey === index} target={`apiKey-${index}`}>
                                                            Click to copy
                                                        </Tooltip>
                                                        <Button color="link" className="show-button" onClick={() => toggleShowKey(index)}>
                                                            {showKey === index ? (
                                                                <FontAwesomeIcon icon={faEyeSlash} className="show-icon" />
                                                            ) : (
                                                                <FontAwesomeIcon icon={faEye} className="show-icon" />
                                                            )}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Badge color={key.status === 'active' ? 'success' : 'secondary'} className="status-badge">
                                                            {key.status?.toUpperCase()}
                                                        </Badge>
                                                    </td>
                                                    <td className="date-column">
                                                        {new Date(key.createdAt).toLocaleDateString('en-US', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                    </td>
                                                    <td className="actions-column">
                                                        {key.status === 'active' ? (
                                                            <>
                                                                <Button outline color="primary" className="rotate-button" style={{ borderRadius: '30px', padding: '6px 14px' }}>
                                                                    Rotate
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    className="revoke-button"
                                                                    style={{ borderRadius: '30px', padding: '6px 14px' }}
                                                                    onClick={() => confirmRevoke(key)}
                                                                >
                                                                    Revoke
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Container>
                            )}
                        </Card>
                    </Col>
                </Row>
            </>}

            {/* Revoke Modal */}
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Revoke API Key</ModalHeader>
                <ModalBody>
                    The key starting with <span style={{ fontFamily: 'monospace', background: '#f0f0f0', padding: '2px 5px', borderRadius: '5px' }}>{selectedKey?.key.slice(0, 15)}...</span> will be immediately invalidated.
                    All future API requests made with it will be rejected. This cannot be undone.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" style={{ borderRadius: '25px', padding: '5px 15px' }} onClick={toggleModal}>Cancel</Button>
                    <Button color="danger" style={{ borderRadius: '25px', padding: '5px 15px' }} onClick={() => revokeApiKey(selectedKey)}>Revoke</Button>
                </ModalFooter>
            </Modal>

            <ToastContainer />
        </Container>
    );
};

export default DevelopersPage;
