import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Button, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'reactstrap-date-picker';

const TimePeriodPage = () => {
  const { updateQuizData, quizData } = useContext(QuizContext);
  const [startDate, setStartDate] = useState(quizData.startDate || '');
  const [endDate, setEndDate] = useState(quizData.endDate || '');
  const [totalQuestions, setTotalQuestions] = useState(12);
  const currentQuestion = 1; // This should be dynamically updated based on the current question index

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (startDate && endDate) {
      updateQuizData({ startDate, endDate, totalQuestions });
      navigate('/quiz/employee-count');
    }
  };

  const handleSelectLastFiscalYear = () => {
    setStartDate('2023-04-01');
    setEndDate('2024-03-31');
  };

  const handleSelectLastCalendarYear = () => {
    setStartDate('2023-01-01');
    setEndDate('2023-12-31');
  };

  const handleStartDateChange = (value) => {
    console.log(value);
    setStartDate(value);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
  };

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="progress-wrapper sticky-top mb-3">
                  <div className="d-flex justify-content-between mb-1">
                    <span>{currentQuestion} / {totalQuestions}</span>
                  </div>
                  <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                </div>
                <h2 className="mb-4">What is the time period you want to calculate emissions for?</h2>
                <Row>
                  <Col lg={6} className="mb-3">
                    <DatePicker
                      id="from"
                      value={startDate}
                      onChange={(v, formattedValue) => handleStartDateChange(formattedValue)}
                      placeholder="From"
                      dateFormat="MM-dd-yyyy"
                      showClearButton={false}
                      className="form-control"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <DatePicker
                      id="to"
                      value={endDate}
                      onChange={(v, formattedValue) => handleEndDateChange(formattedValue)}
                      placeholder="To"
                      dateFormat="MM-dd-yyyy"
                      showClearButton={false}
                      className="form-control"
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={6}>
                    <Button onClick={handleSelectLastFiscalYear} className="btn btn-outline-light btn-success">
                      Last financial year
                    </Button>
                  </Col>
                  <Col lg={6}>
                    <Button onClick={handleSelectLastCalendarYear} className="btn btn-outline-light btn-success">
                      Last calendar year
                    </Button>
                  </Col>
                </Row>
                <Button onClick={handleNextClick} disabled={!startDate || !endDate} className="btn btn-primary btn-block">
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TimePeriodPage;
