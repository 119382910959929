import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const PricingData = [
  {
    title: "Standard",
    price: "SAR 80",
    subtitle: "SAR 80 /tCO₂e",
    isChildItem: [
      { id: 1, subItem: "Dashboard & web ordering" },
      { id: 2, subItem: "Diversified offset portfolio" },
      { id: 4, subItem: "Free standard estimates" },
      { id: 5, subItem: "Tech support" },
    ],
    buttonLabel: "Start now",
  },
  {
    title: "Enterprise",
    price: "Custom",
    isChildItem: [
      { id: 1, subItem: "Additional estimates and endpoints" },
      { id: 2, subItem: "Customized portfolio" },
      { id: 3, subItem: "Custom project selection criteria" },
      { id: 4, subItem: "24/7 support" },
      { id: 5, subItem: "Volume pricing" },
    ],
    buttonLabel: "Let's talk",
  },
];

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="pricing">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Simple, transparent pricing</p>
                <h3>Our pay-as-you-go pricing gives you a fair price for only what you use.</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            {PricingData.map((items, key) => (
              <Col key={key} lg={6} md={8}>
                <div className="pricing-box bg-white box-shadow text-center p-5 mt-5 rounded border">
                  <p className="price-title mb-4 pb-3">{items.title}</p>
                  <div className="price-section mb-4">
                    <h1 className="mb-1 price">{items.price}</h1>
                    {items.subtitle && <p className="subtitle">{items.subtitle}</p>}
                  </div>
                  <div className="pricing-features mt-4 pt-4">
                    {items.isChildItem.map((item, idx) => (
                      <p key={idx}>{item.subItem}</p>
                    ))}
                  </div>
                  <div className="mt-5">
                    <Link to={items.price == 'Custom' ? '#contact' : '/login'} className="btn btn-outline-primary w-100" style={{borderRadius: '25px'}}>
                      {items.buttonLabel}
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Pricing;
