// PaymentUpdateStatus.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner, Container, Row, Col, Alert } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { useParams } from "react-router-dom";

const PaymentUpdateStatus = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [statusMessage, setStatusMessage] = useState('Processing your payment...');
    const [paymentData, setPaymentData] = useState(null);
    const { companyId } = useParams();
    // Extract query parameters
    const query = new URLSearchParams(location.search);
    const paymentId = query.get('id');
    const status = query.get('status');
    const message = query.get('message');
    console.log('status', status);
    console.log('message', message);
    useEffect(() => {
        // Function to fetch the payment details from your API using the paymentId
        // const fetchPaymentData = async () => {
        //     try {
        //         const response = await http.get(`/payment-info/${paymentId}`);
        //         setPaymentData(response.data);
        //     } catch (error) {
        //         console.error('Error fetching payment info:', error);
        //     }
        // };

        // if (paymentId) {
        //     fetchPaymentData();
        // }

        // Display message based on payment status
        if (status === 'paid') {
            http.post(API_ENDPOINTS.UPDATE_PAYMENT_INFO, { companyId });
            setStatusMessage('Payment Approved! Redirecting...');
        } else {
            console.log('Payment Rejected. Redirecting...');
            //http.put(API_ENDPOINTS.COMPANY_REVOKE_PAYMENT_SETUP);
            setStatusMessage('Payment Rejected. Redirecting...');
        }

        // Redirect after 3 seconds 
        setTimeout(() => {
            navigate('/profile/billing'); // Redirect to a payments or profile page
        }, 3000);
    }, [status, paymentId, navigate]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
            <div>
                {paymentData && (
                    <div>
                        {/* <p><strong>Payment ID:</strong> {paymentData.paymentId}</p>
                        <p><strong>Amount:</strong> {paymentData.amount} {paymentData.currency}</p>
                        <p><strong>Description:</strong> {paymentData.description}</p> */}
                        {/* <p><strong>Card Company:</strong> {paymentData.cardCompany}</p>
                        <p><strong>Card Number:</strong> {paymentData.cardNumber}</p> */}
                    </div>
                )}
                <Alert color={status === 'paid' ? 'success' : 'danger'} className="mt-4">
                    <h4>{statusMessage}</h4>
                </Alert>
                <p>Please wait while we process your request...</p>
            </div>
        </div>
    );
};

export default PaymentUpdateStatus;