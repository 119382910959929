// SavePaymentComponent.js
import React, { useEffect } from 'react';
import http from '../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../frameworks/basic-rest/api-endpoints';

const SavePaymentComponent = ({ companyId }) => {

    const savePayment = async (payment) => {
        try {
            const paymentInfo = {
                companyId,
                gatewayPaymentId: payment.id,
                status: payment.status,
                amount: payment.amount,
                currency: payment.currency,
                description: payment.description,
                cardCompany: payment.source.company,
                cardNumber: payment.source.number,
                cardToken: payment.source.token,
            };
            console.log(payment.status)
            await http.post(API_ENDPOINTS.INITIAL_UPDATE_PAYMENT_INFO, paymentInfo);
            return payment.status == 'paid';
        } catch (error) {
            console.error('Error saving payment:', error);
            return false;
        }
    };

    useEffect(() => {
        if (window.Moyasar) {
            window.Moyasar.init({
                element: '.mysr-form',
                amount: 1000, // Replace with actual amount
                currency: 'SAR',
                description: `${companyId} Save Card Information`,
                publishable_api_key: process.env.REACT_APP_MOYASAR_API_KEY,
                callback_url: `${window.location.origin}/profile/update-payment-status/${companyId}`,
                methods: ['creditcard', 'mada'],
                credit_card: {
                    save_card: true,
                },
                on_completed: (payment) => {
                    return new Promise((resolve, reject) => {
                        if (savePayment(payment)) {
                            resolve({});
                        } else {
                            reject();
                        }
                    });
                },
            });
        }
    }, [companyId]);

    return <div className="mysr-form"></div>;
};

export default SavePaymentComponent;
