import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import NavbarSubpage from "../../../Layout/NavbarSubpage";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const Privacy = () => {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <NavbarSubpage />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="position-relative" style={{ zIndex: "1", backgroundColor: "#f8f8f8", padding: "40px", borderRadius: "8px" }}>
                        <h1 className="text-center mb-4">Privacy Policy</h1>
                        <p className="text-center text-muted mb-5">Effective June 20, 2024</p>
                        <p className="text mb-4">
                            See also <Link to="/terms">Terms of Service</Link>
                        </p>
                        <p>We at c-mass Inc. (“c-mass”) know you care about how your personal information is used and shared, and we take your privacy seriously.  Please read the following to learn more about our Privacy Policy.  By using or accessing our website and related services (“Website”) in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.</p>

                        ‍<p><h3>What does this Privacy Policy cover?</h3>
                            This Privacy Policy covers our treatment of personally identifiable information (“Personal Information”) that we gather when you are accessing or using our Website, but not to the practices of companies we don’t own or control, or people that we don’t manage.  We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Website, including to provide and improve our services, to contact you and allow other users to contact you, to fulfill your requests for certain products and services, and to analyze how you use the Website. In certain cases, we may also share some Personal Information with third parties, but only as described below.

                            We do not knowingly collect or solicit Personal Information from anyone under the age of 16. If you are under 16, please do not attempt to register for the Website or send any Personal Information about yourself to us. If we learn that we have collected Personal Information from a child under age 16, we will delete that information as quickly as possible. If you believe that a child under 16 may have provided us Personal Information, please contact us at privacy@c-mass.com.
                        </p>
                        ‍<p><h3>‍Will c-mass ever change this Privacy Policy?</h3>
                            We’re constantly trying to improve our Website, so we may need to change this Privacy Policy from time to time as well, but we will do our best to alert you to changes by placing a notice on www.c-mass.com/privacy, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Website, and you are still responsible for reading and understanding them.  If you use the Website after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.  Use of information we collect now is subject to the Privacy Policy in effect at the time such information is collected.

                            ‍Where does c-mass collect Personal Information from?
                            ‍We collect and store information about you from you when you provide it directly to us, and when our Website automatically collects information about you (for example, through the use of cookies on our website). Such data may also be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email address.
                        </p>
                        ‍<p><h3>‍What Personal Information does c-mass collect?</h3>
                            ‍We receive and store any information you knowingly provide to us.  For example, if you submit a form through the “Contact” page, we will collect Personal Information such as your name and email address. Certain information may be required to register with us or to take advantage of some of our features. The categories of Personal Information we collect may concern you or others and may include, but is not limited to: (a) name; (b) zip code; (c) email address; (d) telephone number; (e) mailing address; (f) demographic information (e.g., gender, age, political preference, education, race or ethnic origin, and other information relevant to user surveys and/or offers); (g) date of birth; (h) photographs; (i) information about your business or project; (j) video or audio files; (k) in certain circumstances, payment and/or identity verification information; and/or (l) any other content you include in communications communications with us. It may also include information specific to services you are requesting through the Platform, such as a business name or service description. You are not required to provide us with such information, but certain features of the Platform may not be accessible or available, absent the provision of the requested information. We also collect information about you when you use our services, including browsing our websites and taking certain actions within the services.
                        </p>
                        ‍‍<p><h3>How does c-mass use Personal Information it collects?</h3>
                            ‍We use your information to operate, improve, understand, and personalize the Website, and for the following purposes:
                            - To meet or fulfill the reason you provided the information to us.
                            - To communicate with you about the Website, including announcements, updates or offers.
                            - To provide support and assistance for the Website.
                            - To create and manage your account or other user profiles, if applicable.
                            - To personalize website content and communications based on your preferences.
                            - To process orders or other transactions.
                            - To respond to user inquiries and fulfill user requests.
                            - To improve and develop the Website, including testing, research, analysis and product development.
                            - To protect against or deter fraudulent, illegal or harmful actions and maintain the safety, security and integrity of our Website.
                            - To comply with our legal or contractual obligations and resolve disputes.
                            - To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
                            - For any other business purpose stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws.

                            As noted in the list above, we may communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers or email you about your use of the Website. Also, we may receive a confirmation when you open an email from us, which helps us improve our services. If you do not want to receive communications from us, please indicate your preference by emailing us at privacy@c-mass.com.
                        </p>
                        ‍<p><h3>‍Will c-mass share any of my Personal Information?</h3>
                            ‍We do not rent or sell your Personal Information in personally identifiable form to anyone. However, we may choose to buy or sell assets, and may share and/or transfer account, contact, and other Personal Information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.

                            In some circumstances, we employ other companies and service providers to perform tasks on our behalf and need to share your information with them to provide products or services to you; in such cases, we share Personal Information for the following business purposes:
                            - Auditing related to a current interaction and concurrent transactions.
                            - Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.
                            - Debugging to identify and repair errors that impair existing intended functionality.
                            - Short-term, transient use of Personal Information that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction.
                            - Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.
                            - Undertaking internal research for technological development and demonstration.
                            - Undertaking activities to verify or maintain the quality or safety of a service or device that we own, manufacture, was manufactured for us, or control.

                            We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply this Privacy Policy and other agreements; or protect the rights, property, or safety of c-mass, our employees, our users, or others.

                            We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our partners. We may also provide aggregate usage information to our partners (or allow partners to collect that information from you), who may use such information to understand how often and in what ways people use our Website, so that they, too, can provide you with an optimal online experience.
                        </p>
                        ‍<p><h3>‍Is Personal Information about me secure?</h3>
                            ‍We endeavor to protect the privacy of your Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security.  Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.
                        </p>
                        ‍<p><h3>‍What Personal Information can I access?</h3>
                            ‍The Website currently does not include the ability to access your information; however, the information you can view, update, and delete may change as the Website changes.  If you have any questions about the information we have on file about you (including any request to delete, correct, or update that information), please contact us at privacy@c-mass.com.
                        </p>

                        ‍<p><h3>‍What choices do I have?</h3>
                            ‍You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features. You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our records even if you request deletion of such information from your account. We may use aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. What if I have questions about this Privacy Policy? If you have any questions or concerns regarding our privacy policies, please send us a detailed message to privacy@c-mass.com and we will try to resolve your concerns.
                        </p>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Privacy;
