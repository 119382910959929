import React, { useContext, useState, useEffect } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row } from "reactstrap";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from 'react-bootstrap/Spinner';
import UserContext from "../../contexts/user/UserContext";
import { useNavigate } from 'react-router-dom';

const CarbonFootprintSummaryPage = () => {
  const { quizData, updateQuizData } = useContext(QuizContext);
  const { user } = useContext(UserContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function saveData() {
      try {
        const response = await http.post(API_ENDPOINTS.SAVE_COMPANY_DATA, {quizData, user});
        setLoading(false);
        setData(response.data);
        updateQuizData(null);
        navigate('/profile/dashboard');
      } catch (error) {
        console.error("Error fetching sectors:", error);
      }
    }

    saveData();
  }, []);

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
          <h2 className="text-center">
                  Thank you for completing the quiz. Based on the information provided, we will calculate your company's carbon footprint.
                </h2>
            <Row className="align-items-center">
              <Spinner />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CarbonFootprintSummaryPage;
