import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import { Link } from 'react-router-dom';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const CompanyNamePage = () => {
  const [companyName, setCompanyName] = useState('');
  const { updateQuizData } = useContext(QuizContext);
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleNextClick = () => {
    if (companyName) {
      updateQuizData({ companyName });
      navigate('/quiz/vat-number');
    }
  };

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="welcome-page">
                  <h2>What is the name of your company?</h2>
                  <div className="mt-4">
                    <input
                      type="text"
                      value={companyName}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Company Name"
                    />

                  </div>
                  <div className="mt-4">
                    <button onClick={handleNextClick} disabled={!companyName} className="btn btn-primary mt-2">
                      Next
                    </button>

                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyNamePage;
