import React from 'react';
import { Container, Row } from "reactstrap";
import ProductShowcase from './ProductShowcase';
import estimateImpactImg from '../assets/images/estimate-impact.png';
import orderApiIntegrationImg from '../assets/images/order-api.png';
import eventCalcImg from '../assets/images/event-calc.png';

const ProductsSectionAR = () => {
    return (
        <React.Fragment>
            <section className="section" id="portfolio" dir="rtl" style={{ textAlign: "right" }}>
                <Container fluid={true}>
                    <div className="header-title text-center">
                        <h3>شراء أرصدة الكربون لا يجب أن يكون صعباً</h3>
                        <div className="title-border mt-3"></div>
                        <p className="title-desc text-muted mt-3">
                            إليك بعض الطرق التي يمكنك من خلالها دمج نظامك بسهولة مع c-mass لتقدير الانبعاثات وتعويض تأثيرك الكربوني.
                        </p>
                    </div>
                    <Row className="mt-5 pt-2">
                        <ProductShowcase
                            title="تقدير الأثر الكربوني"
                            description="تساعدك c-mass على تقدير الأثر الكربوني بدقة لعمليات الشحن، الحافلات، مشاركة الركوب، التوصيل، أو العمليات الأخرى لأسطولك."
                            imgSrc={estimateImpactImg}
                            btnText="تحدث إلى المبيعات"
                            btnLink="#contact"
                            num={1}
                        />
                        <ProductShowcase
                            title="دمج مع منتجك"
                            description="ادمج أرصدة الكربون بسهولة في كل معاملة باستخدام واجهة برمجة التطبيقات سهلة الاستخدام الخاصة بـ c-mass، مما يتيح لعملائك إحداث تأثير إيجابي مع كل عملية شراء."
                            imgSrc={orderApiIntegrationImg}
                            btnText="ربط واجهة برمجة التطبيقات"
                            btnLink="#"
                            num={2}
                        />
                        <ProductShowcase
                            title="حاسبة الكربون للفعاليات"
                            description="قم بدمج حاسبة الكربون للفعاليات في موقع الويب الخاص بفعاليتك باستخدام واجهة برمجة التطبيقات الخفيفة الخاصة بنا، مما يمكن الحاضرين لديك من اتخاذ إجراءات مؤثرة للمناخ."
                            imgSrc={eventCalcImg}
                            btnText="حاسبة الكربون للفعاليات"
                            btnLink="#"
                            num={3}
                        />
                    </Row>
                </Container>
            </section>
        </React.Fragment>

    );
};

export default ProductsSectionAR;
