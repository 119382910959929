import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/CO_certification_logo.jpg";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/image.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";

const ServicesCard = (props) => {
  return (
    <Col lg={3} md={5} className="d-flex justify-content-center">
      <div
        className={
          props.isShadow
            ? "service-box text-center mt-4 box-shadow"
            : "service-box text-center mt-4"
        }
      >
        <img src={props.imgsrc} alt="" className="img-fluid service-image" />
        <p>{props.title}</p>
      </div>
    </Col>
  );
};

const ServicesData = [
  {
    imgsrc: img04,
    title: "",
    caption: "",
  },
  // {
  //   imgsrc: img03,
  //   title: "",
  //   caption: "",
  // },
  {
    imgsrc: img01,
    title: "",
    caption: "",
  },
  {
    imgsrc: img02,
    title: "",
    caption: "",
  },
  {
    imgsrc: img05,
    title: "",
    caption: "",
  },
  // {
  //   imgsrc: img06,
  //   title: "",
  //   caption: "",
  // },
];

const ServicesAR = () => {
  return (
    <section className="section" id="services" dir="rtl" style={{ textAlign: "right" }}>
  <Container>
    <Row className="justify-content-center">
      <Col lg={7}>
        <div className="header-title text-center">
          <h3>
            الامتثال لرؤية المملكة{" "}
            <span className="text-primary">2030</span> في تقليل انبعاثات ثاني أكسيد الكربون
          </h3>
          <div className="title-border mt-3"></div>
          <p className="text-muted mt-3">
           نستخدم أحدث العلوم لإنشاء محفظة متنوعة من المشاريع، مصممة خصيصًا لتقليل المخاطر وتقديم أقصى تأثير على المناخ. الآن، يمكنك الحصول على هذه الاستراتيجية المتطورة بسعر معقول.
          </p>
        </div>
      </Col>
    </Row>

    <Row>
      {ServicesData.map((value, idx) => (
        <ServicesCard
          key={idx}
          imgsrc={value.imgsrc}
          title={value.title} // Ensure Arabic titles in ServicesData
          caption={value.caption} // Ensure Arabic captions in ServicesData
          isShadow={value.isShadow}
        />
      ))}
    </Row>
  </Container>
</section>

  );
};

export default ServicesAR;
