export const API_ENDPOINTS = {
    SECTORS: '/sectors',
    SAVE_COMPANY_DATA: '/company/save-company-data',

    LOGIN: '/user/login',
    SIGNUP: '/user/signup',
    PROMO_SIGNUP: '/user/promo-signup',
    UPDATE_USER_INFO: '/user',

    SCOPES_EMISSIONS: '/emissions/scopes',
    TOP_CONTRIBUTORS: '/emissions/top-emissions',
    FULL_REPORT: '/emissions/full-report',

    COMPANY: '/company',
    PURCHASE_ORDER: '/company/order',
    COMPANY_ORDERS_SUMMERY: '/company/ordersSummery',
    COMPANY_ORDERS: '/company/orders',
    COMPANY_REVOKE_PAYMENT_SETUP: '/company/revoke-paymentSetup',
    COMPANY_ADD_PAYMENT_SETUP: '/company/add-paymentSetup',

    PORTFOLIO: '/portfolio',
    GET_PORTFOLIO: '/portfolio/getPortfolio',

    INQUIRY: '/mail/inquiry',

    SUBACCOUNTS: '/subaccount',
    GET_SUBACCOUNTS: '/subaccount',
    CREATE_SUBACCOUNTS: '/subaccount/create',

    ORDER_DETAILS: '/order',
    ORDERS_SUMMERY: '/order/summery',
    ORDERS_FULFILLED: '/order/fulfilled',
    ORDERS: '/order/orders-list',
    SUBMIT_ORDER: '/order',

    CREATE_APIKEY: '/apikeys/create',
    REVOKE_SANDBOX_APIKEY: '/apikeys/revoke',
    GET_LIVE_API_KEYS: '/apikeys/live',
    GET_SANDBOX_API_KEYS: '/apikeys/sandbox',

    PUBLIC_ORDER_DETAILS: '/public/order',
    PUBLIC_SHARE_IMPACT: '/public/share-impact',
    PUBLIC_SHARE_ORDER_IMPACT: '/public/share-order-impact',

    PROJECT: '/project',
    PROJECTS_BY_IDS: '/project/byIds',
    PROJECT_RECORDS_BY_IDS: '/project/projectRecordsById',
    SUPPORTED_PROJECTS: '/project/supported',

    LEADS: '/admin/leads',
    LEAD: '/admin/leads',
    PROJECTS: '/admin/projects',
    PROJECT_CATEGORIES: '/admin/projects/categories',
    CREDIT_BATCHES: '/admin/projects/credit-batches',
    USERS: '/admin/users',
    AUDIT_LOGS: '/admin/audit',
    ALL_ORDERS: '/admin/orders',
    ADMIN_ORDER_DETAILS: '/admin/order-details',

    SAVE_PAYMENT_INFO: '/payment-info/setup-payment',
    INITIAL_UPDATE_PAYMENT_INFO: '/payment-info/initial-update-payment',
    UPDATE_PAYMENT_INFO: '/payment-info/update-payment',
    GET_PAYMENT_INFO: '/payment-info',
    GET_ALL_PAYMENT_INFO: '/payment-info/all-payments',
}