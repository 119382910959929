import React from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";

import img1 from "../assets/images/blog/1730113501007.jpg";
import img2 from "../assets/images/blog/img-2.png";
import img3 from "../assets/images/blog/img-5.png";

import img5 from "../assets/images/nz-logo-dk.png";
import img6 from "../assets/images/users/img-2.png";
//import img7 from "../assets/images/users/image.png";

// BlogCard

const BlogCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="blog-box box-shadow rounded mt-4 p-3">
          <div className="blog-img">
            <img src={props.imgsrc} className="img-fluid rounded" alt="" />
            <div className="read-more">
              <Link to={props.url ? props.url : '/#'}>
                <i className="mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="mt-4">
            <Link to="/#" className="primary-link">
              <h5 className="fs-19 mb-1">{props.title}</h5>
            </Link>
            <p className="text-muted mt-2">{props.subtitle}</p>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={props.imgsrc2}
                  alt=""
                  height="45"
                  className="rounded-circle me-2"
                />
                <p className="text-muted mb-0">{props.name}</p>
              </div>
              <p className="mb-0 float-end text-muted">
                <i className="mdi mdi-clock-time-four-outline align-middle me-1 text-primary"></i>{" "}
                {props.time}
              </p>
            </div>
          </div>
        </div>
        {/* <!--end blog--> */}
      </Col>
    </React.Fragment>
  );
}

// Blog Data

const BlogData = [
  {
    imgsrc1: img1,
    title: "Carbon Offset Certification and c-mass are excited to announce a new partnership!",
    subtitle:
      "c-mass and Carbon Offset Certification aim to drive innovation in carbon certification and environmental compliance in Saudi Arabia",
    imgsrc2: img5,
    name: "c-mass news",
    time: "7 دقيقة",
    url: 'https://www.linkedin.com/posts/c-mass_c-mass-and-carbon-offset-certification-aim-ugcPost-7257461302762106881-8GtS?utm_source=share&utm_medium=member_desktop'
  },
  {
    imgsrc1: img2,
    title: "GCOM Guidelines",
    subtitle:
      "SAUDI ARABIA’S GREENHOUSE GAS CREDITING & OFFSETTING MECHANISM (GCOM)",
    //imgsrc2: img6,
    name: "GCOM",
    time: "32 دقيقة",
    url: 'https://gcom.cdmdna.gov.sa/Downloads/KSA-GCOM-GUIDELINE.pdf'
  },
  {
    imgsrc1: img3,
    title: "Saudi Arabia takes bold strides toward greener future and carbon neutrality",
    subtitle:
      "Saudi Arabia is pursuing carbon neutrality with a multi-pronged approach that touches on everything from transportation to energy. (SPA)",
    //imgsrc2: img7,
    name: "Arab News",
    time: "45 دقيقة",
    url: 'https://www.arabnews.com/node/2470011/business-economy'
  },
];

const Blog = () => {
  return (
    <React.Fragment>
      <section className="section" id="blog">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-5">
                <p className="text-uppercase text-muted mb-2">الأخبار</p>
                <h3>أخر المقالات و الأخبار</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Blog Component Start*/}

            {BlogData.map((value, idx) => (
              <BlogCard
                key={idx}
                imgsrc={value.imgsrc1}
                title={value.title}
                subtitle={value.subtitle}
                imgsrc2={value.imgsrc2}
                name={value.name}
                time={value.time}
                url={value.url}
              />
            ))}

            {/* Blog Component End*/}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Blog;
