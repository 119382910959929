import React from "react";
import { Col, Container, Row } from "reactstrap";

const ProcessCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className={props.number !== "04" ? "process-box process-border text-center" : "process-box text-center"}>
          <div className="process-count text-white mt-4">
            <h3 className="process-number">{props.number}</h3>
          </div>

          <div className="process-content bg-light mt-5 rounded p-4">
            <h5 className="fs-18">{props.step}</h5>
            <p className="text-muted mb-0">{props.caption}</p>
          </div>
        </div>
        {/* <!--end process-box--> */}
      </Col>
    </React.Fragment>
  );
}

// Process Data
const ProcessDataAR = [
  {
    number: "01",
    step: "إنشاء حساب",
    caption: "سجل للحصول على حساب مجاني وابدأ الاختبار فورًا في بيئة الساندبوكس.",
  },
  {
    number: "02",
    step: "تهيئة الفوترة",
    caption: "أدخل تفاصيل الدفع الخاصة بك للاستعداد للشراء عبر واجهة برمجة التطبيقات.",
  },
  {
    number: "03",
    step: "ربط واجهة برمجة التطبيقات",
    caption: "استخدم نقطة النهاية 'Order' لإرسال طلب POST وشراء أرصدة الكربون بالكيلوجرام دون أي رسوم إضافية.",
  },
  {
    number: "04",
    step: "شراء أرصدة الكربون",
    caption: "اختر من مجموعة متنوعة من أرصدة الكربون عالية الجودة والمعتمدة من المشاريع المحلية والعالمية لتعويض انبعاثاتك.",
  },
];

const ProcessAR = () => {
  return (
    <React.Fragment>
      <section className="bg-process section" id="process" dir="rtl" style={{ textAlign: "right" }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <h3>خطوات الربط</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  قم بشراء محفظة فعالة من حيث التكلفة، مدفوعة بالعلوم، من أرصدة الكربون عالية الجودة ذات النزاهة المثبتة بسهولة.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-2">
            {/* Process Component Start */}

            {ProcessDataAR.map((value, idx) => (
              <ProcessCard
                key={idx}
                number={value.number}
                step={value.step}
                caption={value.caption}
                isShow={value.isShow}
              />
            ))}

            {/* Process Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ProcessAR; 

