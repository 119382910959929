import React, { useState, useContext, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    FormGroup,
    Label,
    Spinner,
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../contexts/user/UserContext";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";

const AccountPage = () => {
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(true);
    const { user, signIn } = useContext(UserContext); // Assuming setUser is available in UserContext
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        companyName: "",
    });

    const toggleProfileModal = () => setIsProfileModalOpen(!isProfileModalOpen);
    const togglePasswordModal = () => setIsPasswordModalOpen(!isPasswordModalOpen);

    useEffect(() => {
        if (!user) {
            console.log("User is not available, skipping request...");
            return;
        }
        const fetchData = async () => {
            try {
                const response = await http.post(API_ENDPOINTS.COMPANY);
                setCompany(response.data);
                setFormData({
                    name: user.name,
                    email: user.email,
                    companyName: response.data.name || "",
                });
            } catch (error) {
                console.error("Error fetching company data:", error);
                toast.error("Error loading company information.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const updateUserInfo = async () => {
        try {
            const response = await http.put(API_ENDPOINTS.UPDATE_USER_INFO, {
                name: formData.name,
                email: formData.email,
                company: { name: formData.companyName },
            });
            if (response.status == 200) {
                // Update user context and company state
                signIn({
                    ...user, // Merge existing user data
                    name: formData.name,
                    email: formData.email,
                });
                setCompany((prev) => ({ ...prev, name: formData.companyName }));
                toggleProfileModal();
                toast.success("Profile updated successfully!");

            } else {
                toast.error(`Error updating profile: ${response.data.message}`);
            }
        } catch (error) {
            console.error("An error occurred while updating user info:", error);
            toast.error("Failed to update profile. Please try again.");
        }
    };

    return (
        <Container className="dashboard">
            {/* Profile Info Section */}
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Row className="mt-5">
                        <Col lg={12}>
                            <h1>Account</h1>
                        </Col>
                        <Col lg={12} className="mt-4">
                            <div className="billing-card">
                                <h4>Profile Info</h4>
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <p>
                                            <strong>Name:</strong> {user.name}
                                        </p>
                                        <p>
                                            <strong>Email:</strong> {user.email}
                                        </p>
                                        <p>
                                            <strong>Company:</strong> {company.name}
                                        </p>
                                    </Col>
                                </Row>
                                <Button
                                    color="primary"
                                    className="mt-3"
                                    style={{ width: "10%", borderRadius: "25px" }}
                                    onClick={toggleProfileModal}
                                >
                                    Edit
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    {/* Security Section */}
                    <Row className="mt-5">
                        <Col lg={12}>
                            <div className="billing-card">
                                <h4>Security</h4>
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <p>
                                            <strong>Login methods:</strong> Email and password
                                        </p>
                                        <p>
                                            <strong>Password:</strong> ******
                                        </p>
                                        <Button
                                            color="dark"
                                            className="mt-3"
                                            style={{ borderRadius: "25px" }}
                                            onClick={togglePasswordModal}
                                        >
                                            Change Password
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* Profile Modal */}
                    <Modal isOpen={isProfileModalOpen} toggle={toggleProfileModal}>
                        <ModalHeader toggle={toggleProfileModal}>Update Profile</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input
                                    type="text"
                                    id="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="companyName">Company</Label>
                                <Input
                                    type="text"
                                    id="companyName"
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="dark"
                                onClick={toggleProfileModal}
                                style={{ borderRadius: "25px" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                style={{ borderRadius: "25px" }}
                                onClick={updateUserInfo}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>

                    {/* Password Modal */}
                    <Modal isOpen={isPasswordModalOpen} toggle={togglePasswordModal}>
                        <ModalHeader toggle={togglePasswordModal}>Change Password</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="currentPassword">Current Password</Label>
                                <Input
                                    type="password"
                                    id="currentPassword"
                                    placeholder="Enter current password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newPassword">New Password</Label>
                                <Input
                                    type="password"
                                    id="newPassword"
                                    placeholder="Enter new password"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Input
                                    type="password"
                                    id="confirmPassword"
                                    placeholder="Confirm new password"
                                />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="dark"
                                onClick={togglePasswordModal}
                                style={{ borderRadius: "25px" }}
                            >
                                Cancel
                            </Button>
                            <Button color="primary" style={{ borderRadius: "25px" }}>
                                Change Password
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )}
            <ToastContainer />
        </Container>
    );
};

export default AccountPage;
