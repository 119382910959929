import React from 'react';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const ExpensesSectionOverviewPage = () => {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate('/quiz/total-expenses-details');
  };

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <h1>Nice work!</h1>
                <h2>Let’s get going on your expenses to get the full picture. Before you start:</h2>
                <ul className="custom-list">
                  <li>
                    <h3>Start with the big stuff</h3>
                    <p className="text-muted fs-17">
                      Focus on your biggest expenses first as they’ll likely have the most impact on your emissions estimate.
                    </p>
                  </li>
                  <li>
                    <h3>Feel free to jump around</h3>
                    <p className="text-muted fs-17">
                      Our spend categories may not fit your expense structure. Skip around if you need to and match to the best of your ability.
                    </p>
                  </li>
                  <li>
                    <h3>Phone a friend(ly) accountant</h3>
                    <p className="text-muted fs-17">
                      You may want to complete this next section with your company’s finance guru (unless that’s you).
                    </p>
                  </li>
                </ul>
                <button onClick={handleNextClick} className="btn btn-primary mt-2">Next</button>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ExpensesSectionOverviewPage;
