import React, { useState, useContext } from "react";
import ModalVideo from "react-modal-video";
import Business from "../../assets/images/home/Business.mp4";
import UserContext from "../../contexts/user/UserContext";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Label, Input, FormGroup, Button } from "reactstrap";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import http from '../../frameworks/basic-rest/http';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Section = () => {
  const [isOpen, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    comments: '',
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await http.post(API_ENDPOINTS.INQUIRY, formData);
      toast.success('Email sent successfully!');

      // Clear the form fields
      setFormData({
        name: '',
        email: '',
        number: '',
        comments: '',
      });

    } catch (error) {
      toast.error('Failed to send email. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <section className="bg-home5" id="home" dir="rtl" style={{ textAlign: "right" }}>
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="ms-4">
                  {/* <h6 className="sub-title mb-3">c-mass</h6> */}
                  <h1 className="mb-4">
                    دمج تأثير <span className="text-primary">حقيقي</span> على المناخ{" "}
                  </h1>
                  <p className="text-muted fs-17">
                    c-mass توفر الحل الأمثل للتأثير الكربوني البرمجي. قم بالربط مع واجهة برمجة التطبيقات الخاصة بنا بسهولة في منتجك لتوفير تجربة مستخدم بديهية وسهلة، مصممة خصيصًا لتتناسب مع رؤيتك.
                  </p>
                  <div className="mt-4">
                    {user ? (
                      <Link to="/profile/home" className="btn btn-primary mt-2" style={{ borderRadius: '25px' }}>
                        الذهاب إلى لوحة التحكم
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
              {user ? (
                <></>
              ) : (
                <div className="mt-4">
                  <Link to="/signup" className="btn btn-primary mt-2" style={{ borderRadius: '25px' }}>
                    تسجيل
                  </Link>
                </div>
              )}
            </Row>
          </div>
        </Container>
      </section>
      {/* <!-- END HOME --> */}
      {/* <!-- START SHAPE --> */}
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 250"
          >
            <g mask='url("#SvgjsMask1036")' fill="none">
              <path
                d="M 0,214 C 96,194 288,120.8 480,114 C 672,107.2 768,201.4 960,180 C 1152,158.6 1344,41.6 1440,7L1440 250L0 250z"
                fill="rgba(255, 255, 255, 1)"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1036">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
      <ToastContainer />
    </React.Fragment>

  );
};

export default Section;
