import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "framer-motion"; // Hook to detect when components come into view
import NavBar from "../../Layout/Navbar";
import Section from "./Section";
import Services from "../../components/Services";
import About from "../../components/About";
import Process from "../../components/Process";
import Pricing from "../../components/Pricing";
import Integration from "../../components/Integration";
import Blog from "../../components/Blog";
import CTA from "../../components/CTA";
import Contact from "../../components/Contact";
import Footer from "../../Layout/Footer";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import APIFeatures from "../../components/APIFeatures";
import ProductsSection from "../../components/ProductsSection";
import ImpactSection from "../../components/ImpactSection";

const Layout5 = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check the user's color scheme preference
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkModeMediaQuery.matches);

    // Add event listener to handle changes in the color scheme
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener("change", handleChange);

    // Cleanup the event listener on component unmount
    return () => darkModeMediaQuery.removeEventListener("change", handleChange);
  }, []);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const slideInFromLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const slideInFromRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  // Component to handle animations on scroll
  const AnimatedSection = ({ children, variants }) => {
    const controls = useAnimation();
    const ref = React.useRef();
    const inView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [inView, controls]);

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={variants}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <React.Fragment>
      <NavBar isDark={isDarkMode} />
      <AnimatedSection variants={fadeIn}>
        <Section />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromLeft}>
        <Services />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromRight}>
        <About />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <Integration />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromLeft}>
        <Process />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <APIFeatures />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromLeft}>
        <ProductsSection />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <ImpactSection />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromRight}>
        <Pricing />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <CTA />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromLeft}>
        <Blog />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromRight}>
        <Contact />
      </AnimatedSection>
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout5;
