import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import NavbarSubpageAR from "../../../Layout/NavbarSubpageAR";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const PrivacyAR = () => {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <NavbarSubpageAR />
            <section className="bg-home5" id="home" dir="rtl" style={{ textAlign: "right" }}>
                <div className="bg-overlay"></div>
                <Container>
                    <div
                        className="position-relative"
                        style={{
                            zIndex: "1",
                            backgroundColor: "#f8f8f8",
                            padding: "40px",
                            borderRadius: "8px",
                        }}
                    >
                        <h1 className="text-center mb-4">سياسة الخصوصية</h1>
                        <p className="text-center text-muted mb-5">سارية المفعول اعتبارًا من 20 يونيو 2024</p>
                        <p className="text mb-4">
                            انظر أيضًا <Link to="/ar/terms">شروط الخدمة</Link>
                        </p>
                        <p>
                            نحن في شركة c-mass Inc. ("c-mass") نعلم أنك تهتم بكيفية استخدام معلوماتك الشخصية
                            ومشاركتها، ونحن نتعامل مع خصوصيتك بجدية. يرجى قراءة ما يلي لمعرفة المزيد عن سياسة
                            الخصوصية الخاصة بنا. باستخدامك أو وصولك إلى موقعنا الإلكتروني والخدمات ذات الصلة
                            ("الموقع") بأي طريقة، فإنك تقر بأنك تقبل الممارسات والسياسات الموضحة في سياسة الخصوصية
                            هذه، وتوافق بموجب هذا على أننا سنجمع ونستخدم ونشارك معلوماتك بالطريقة التالية.
                        </p>

                        <p>
                            <h3>ما الذي تغطيه سياسة الخصوصية هذه؟</h3>
                            تغطي سياسة الخصوصية هذه تعاملنا مع المعلومات الشخصية التي يمكن التعرف عليها ("المعلومات
                            الشخصية") التي نجمعها عندما تصل إلى موقعنا الإلكتروني أو تستخدمه. لا تسري سياسة الخصوصية
                            هذه على ممارسات الشركات التي لا نمتلكها أو نتحكم فيها، أو الأشخاص الذين لا نديرهم. نقوم
                            بجمع أنواع مختلفة من المعلومات الشخصية من مستخدمينا كما هو موضح بالتفصيل أدناه، ونستخدم
                            هذه المعلومات الشخصية داخليًا فيما يتعلق بالموقع، بما في ذلك لتقديم وتحسين خدماتنا،
                            للتواصل معك والسماح للمستخدمين الآخرين بالتواصل معك، لتلبية طلباتك لمنتجات وخدمات معينة،
                            ولتحليل كيفية استخدامك للموقع. في بعض الحالات، قد نشارك بعض المعلومات الشخصية مع أطراف
                            ثالثة، ولكن فقط كما هو موضح أدناه.
                        </p>

                        <p>
                            <h3>هل ستقوم c-mass بتغيير سياسة الخصوصية هذه؟</h3>
                            نحن نسعى دائمًا لتحسين موقعنا الإلكتروني، لذا قد نحتاج إلى تغيير سياسة الخصوصية من وقت
                            لآخر أيضًا، لكننا سنبذل قصارى جهدنا لتنبيهك بالتغييرات من خلال وضع إشعار على
                            www.c-mass.com/privacy أو بإرسال بريد إلكتروني إليك. إذا كنت تستخدم الموقع بعد إجراء
                            تغييرات على سياسة الخصوصية، فهذا يعني أنك توافق على جميع التغييرات.
                        </p>

                        <p>
                            <h3>ما المعلومات الشخصية التي تجمعها c-mass؟</h3>
                            نتلقى ونخزن أي معلومات تقدمها لنا عن علم. على سبيل المثال، إذا أرسلت نموذجًا عبر صفحة
                            "الاتصال"، سنجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني.
                        </p>

                        <p>
                            <h3>كيف تستخدم c-mass المعلومات الشخصية التي تجمعها؟</h3>
                            نستخدم معلوماتك لتشغيل الموقع وتحسينه وفهمه وتخصيصه، وللأغراض التالية:
                            <ul>
                                <li>لتلبية السبب الذي قدمت المعلومات لنا من أجله.</li>
                                <li>للتواصل معك بشأن الموقع.</li>
                                <li>لتقديم الدعم والمساعدة.</li>
                                <li>لإنشاء وإدارة حسابك أو ملفات تعريف المستخدم الأخرى.</li>
                                <li>لتحسين المحتوى.</li>
                            </ul>
                        </p>

                        <p>
                            <h3>هل ستشارك c-mass أيًا من معلوماتي الشخصية؟</h3>
                            لا نقوم بتأجير أو بيع معلوماتك الشخصية لشخص محدد. ومع ذلك، قد نشارك معلومات في ظروف
                            محددة كما هو موضح أعلاه.
                        </p>
                    </div>
                </Container>
            </section>
        </React.Fragment>

    );
};

export default PrivacyAR;
