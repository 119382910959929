import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useParams, useOutletContext } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import UserContext from "../../contexts/user/UserContext";
import http from "../../frameworks/basic-rest/p-http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/impactShare.css"; // Create and import your CSS file
import logo from '../../assets/images/nz-logo.png'; // Replace with your actual logo path
import treeIcon from '../../assets/images/trees.png'; // Replace with actual path
import carIcon from '../../assets/images/car.png'; // Replace with actual path
import houseIcon from '../../assets/images/house.png'; // Replace with actual path
import planeIcon from '../../assets/images/plane.png'; // Replace with actual path

import goal1 from '../../assets/images/hung.jpeg';
import goal2 from '../../assets/images/3.jpeg';
import goal3 from '../../assets/images/4.jpeg';
import goal4 from '../../assets/images/6.jpeg';
import goal5 from '../../assets/images/7.jpeg';
import goal6 from '../../assets/images/8.jpeg';
import goal7 from '../../assets/images/11.jpeg';
import goal8 from '../../assets/images/12.jpeg';
import goal9 from '../../assets/images/13.jpeg';
import goal10 from '../../assets/images/14.jpeg';
import goal11 from '../../assets/images/15.jpeg';
import goal12 from '../../assets/images/17.jpeg';
import cMassLogo from '../../assets/images/nz-logo.png'; // Replace with your C-Mass logo path

const ShareOrderImpact = ({ credits }) => {
    const { user } = useContext(UserContext);
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderResponse = await http.get(`${API_ENDPOINTS.PUBLIC_SHARE_ORDER_IMPACT}/${id}`);
                setOrder(orderResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const imgs = [
        goal1,
        goal2,
        goal3,
        goal4,
        goal5,
        goal6,
        goal7,
        goal8,
        goal9,
        goal10,
        goal11,
        goal12,
    ];

    const equivalents = [
        {
            icon: treeIcon,
            altText: 'Trees Planted',
            multiplier: 0.022,
            description: 'new trees planted'
        },
        {
            icon: carIcon,
            altText: 'Cars off the Road',
            multiplier: 0.00021,
            description: 'cars off the road for a year'
        },
        {
            icon: houseIcon,
            altText: 'Homes Energy Usage',
            multiplier: 0.00022,
            description: 'homes\' annual energy usage'
        },
        {
            icon: planeIcon,
            altText: 'Flights',
            multiplier: 0.0011,
            description: 'flights from Riyadh to London'
        }
    ];
    

    return (
        <div className="share-your-impact-page">
            {/* Section 1: Main Impact Statement */}
            <section className="impact-statement py-5" style={{ backgroundColor: '#defcf1', paddingTop: '60px' }}>
                <Container>
                    <Row className="align-items-center">
                        <Col md="6" className="text-center text-md-left">
                            <div className="left-section">
                                <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                    {loading ? <Spinner /> : <p>Thanks to {order.companyName}</p>}
                                </h2>
                                <h1 className="impact-title">I was able to take a climate action</h1>
                            </div>
                        </Col>
                        <Col md="4" className="mt-4 mt-md-0">
                            <div className="right-section p-4" style={{
                                backgroundColor: '#fff',
                                borderRadius: '15px',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            }}>
                                <p className="impact-subtitle">
                                    I have offset
                                </p>
                                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                                <div className="impact-value">
                                    <h2 className="display-3">{loading ? <Spinner /> : order.creditsPurchased}</h2>
                                    <span>kg of CO₂e</span>
                                </div>
                                <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} />
                                <div className="share-icons mt-4 text-center">
                                    {/* Social media icons */}
                                    <a href={`https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fc-mass.co%2Fshare-order-impact%2F${id}&title={articleTitle}&summary={articleSummary}&mini=true`} className="item-icon" style={{ cursor: 'pointer' }} target="_blank">
                                        <i className="bi bi-linkedin mx-2"></i>
                                    </a>
                                    {/* <a href="#" className="item-icon"><i className="bi bi-x mx-2"></i></a>
                                    <a href="#" className="item-icon"><i className="bi bi-facebook mx-2"></i></a>
                                    <a href="#" className="item-icon"><i className="bi bi-link mx-2"></i></a> */}
                                </div>
                            </div>
                        </Col>
                        <Col md="2" className="text-right" style={{ position: 'absolute', right: '30px', top: '10px' }}>
                            <img src={cMassLogo} alt="Powered by C-Mass" style={{ maxWidth: '80px', marginTop: '5px' }} />
                            <p style={{ fontSize: '10px', marginTop: '5px', color: '#666' }}>Powered by c-mass</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Section 2: Climate Impact Comparisons */}
            <section className="climate-impact py-5">
                <Container>
                    <h2 className="text-center mb-5">That's the same climate impact as:</h2>
                    <Row className="text-center">
                        {equivalents.map((equivalent, index) => (
                            <Col md="3" xs="6" className="mb-4" key={index}>
                                <div className="impact-item" style={{
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}>
                                    <img
                                        src={equivalent.icon}
                                        alt={equivalent.altText}
                                        className="mb-3"
                                        style={{ maxWidth: '150px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    />
                                    <h3>
                                        {order?.creditsPurchased
                                            ? (order.creditsPurchased * equivalent.multiplier).toFixed(1)
                                            : 0}
                                    </h3>
                                    <p>{equivalent.description}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>

            </section>

            {/* Section 3: UN Sustainable Development Goals */}
            <section className="sustainable-goals bg-light py-5">
                <Container>
                    <h2 className="text-center mb-4">Kingdom’s Sustainable Development Goals</h2>
                    <p className="text-center mb-5">Our projects support the following Kingdom’s Sustainable Development Goals</p>
                    <Row className="text-center">
                        {imgs.map((img, i) => (
                            <Col lg="2" md="3" xs="4" key={i} className="mb-4">
                                <a href="link-to-goal-1" target="_blank">
                                    <img src={img} alt="No Poverty" className="mb-3" style={{ maxWidth: '80px' }} />
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

        </div>
    );
};

export default ShareOrderImpact;
