import React from 'react';
import { Col, Container, Row } from "reactstrap";
import impactImg from '../assets/images/impact-section.png';

const ImpactSectionAR = () => {
    return (
        <section
          style={{
            display: "flex",
            padding: "40px",
            backgroundColor: "#8ae5c3",
            alignItems: "center",
            direction: "rtl",
            textAlign: "right",
          }}
        >
          <div style={{ flex: 1, marginLeft: "40px" }}>
            <img
              src={impactImg}
              alt="صفحة التأثير على الجهاز اللوحي"
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <h2 style={{ fontSize: "36px", marginBottom: "20px" }}>
              شارك تأثيرك فوراً وبشكل موثوق
            </h2>
            <p style={{ fontSize: "18px", marginBottom: "20px", lineHeight: "1.6" }}>
              اعرض تأثيرك بسهولة في الوقت الفعلي باستخدام صفحة التأثير المستقلة والأداة القابلة للتضمين. استخدم أصول الفيديو والصور الخاصة بنا لسرد قصتك بصوتك الفريد.
            </p>
            <p style={{ fontSize: "18px", marginBottom: "40px", lineHeight: "1.6" }}>
              تواصل مع مبادراتك المناخية بلغة واضحة وسهلة الفهم لتترك انطباعاً دائماً على أصحاب المصلحة.
            </p>
            {/*<button 
                className="btn btn-primary"
            >
                عينة من صفحة التأثير
            </button>*/}
          </div>
        </section>
      );
      
};

export default ImpactSectionAR;
