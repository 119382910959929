import React from "react";
import "../../assets/css/CompanyPage.css";
import NavbarSubpageAR from "../../Layout/NavbarSubpageAR";
import Team from '../../components/Team';

const CompanyPage = () => {
    return (
        <React.Fragment>
            <NavbarSubpageAR />
            <section className="bg-home5" id="home">
                <div className="company-page">
                    <section className="about-us-section" dir="rtl" style={{ textAlign: "right" }}>
                        <div className="about-us-content">
                            <h4>من نحن</h4>
                            <h1>
                                تمكين الشركات من اتخاذ خطوات هادفة نحو مستقبل مستدام
                            </h1>
                            <p>
                                نؤمن بأن لكل فرد دورًا يلعبه في تقليل البصمة الكربونية والتصدي لتغير المناخ. ومع ذلك،
                                يمكن أن يكون التنقل في عالم تعويضات الكربون معقدًا ومربكًا، خاصةً لأولئك الجدد
                                في هذا المجال. لهذا السبب قمنا بإنشاء منصة سهلة وشفافة تبسط العملية.
                            </p>
                            <p>
                                توفر منصتنا للشركات إمكانية الوصول إلى تعويضات كربونية موثوقة وفعالة، مما يمكنها من
                                تعويض الانبعاثات والمساهمة في تحقيق أهداف الاستدامة العالمية. من خلال الجمع بين البساطة
                                والعلم القوي، نساعد الشركات من جميع الأحجام على اتخاذ إجراءات وخلق تأثير ملموس
                                للمناخ.
                            </p>
                        </div>
                    </section>

                    <section className="values-section" dir="rtl" style={{ textAlign: "right" }}>
                        <div className="values-content">
                            <h2>قيمنا</h2>
                            <p>
                                قيمنا الأساسية تقود كل ما نقوم به. هذه المبادئ توجه عملية اتخاذ القرار لدينا، وتشكل
                                أهدافنا، وتكون الأساس لثقافتنا.
                            </p>
                            <div className="values-cards">
                                <div className="value-card">
                                    <h3>تحقيق التغيير الإيجابي</h3>
                                    <p>
                                        كل قرار نتخذه يهدف إلى خلق عالم أفضل. يركز عملنا على تمكين الشركات من اتخاذ خطوات
                                        هادفة وقابلة للتوسع نحو مستقبل مستدام.
                                    </p>
                                </div>
                                <div className="value-card">
                                    <h3>احتضان النمو</h3>
                                    <p>
                                        نحن ملتزمون بالتعلم المستمر والتحسين. كل تحدٍ يمثل فرصة للنمو وتقديم حلول أفضل
                                        لعملائنا.
                                    </p>
                                </div>
                                <div className="value-card">
                                    <h3>التعاون من أجل التأثير</h3>
                                    <p>
                                        العمل الجماعي هو أساس كل ما نقوم به. من خلال العمل معًا وتعزيز التعاون، نضخم
                                        تأثيرنا ونحقق الأهداف المشتركة.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Team />
                </div>
            </section>
        </React.Fragment>
    );
};

export default CompanyPage;
