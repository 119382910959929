// src/Layout/MainLayout.js
import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ProfileTopNav from './ProfileTopNav';
import UserContext from "../contexts/user/UserContext";
import http from "../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../frameworks/basic-rest/api-endpoints";
import { Spinner } from 'reactstrap';

const MainLayout = () => {
    const [company, setCompany] = useState({});
    const [timePeriod, setTimePeriod] = useState('');
    const [loading, setLoading] = useState(true);
    const { user, signOut } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            console.log("User is not available, skipping request...");
            return;
        }
        const fetchData = async () => {
            try {
                const response = await http.post(API_ENDPOINTS.COMPANY);
                setCompany(response.data);
                setTimePeriod(response.data.timePeriod[0]);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    signOut();
                    navigate("/login");
                } else {
                    console.error("Error fetching company data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    return (
        <div className="main-container">
            <Sidebar company={company} />
            <ProfileTopNav user={user} signOut={signOut} company={company}/>
            <div className="content">
                {loading ? <Spinner /> : <Outlet context={{ company, itimePeriod: timePeriod, setTimePeriod, loading }} />}
            </div>
        </div>
    );
};

export default MainLayout;
