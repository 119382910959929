import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, FormGroup, Row, Container, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const HeatingReceivedPage = () => {

  const { quizData, updateQuizData } = useContext(QuizContext);
  const [heatingReceived, setHeatingReceived] = useState(quizData.heatingReceived? quizData.heatingReceived : '');
  const [heatingType, setHeatingType] = useState(quizData.heatingType? quizData.heatingType : '');
  const navigate = useNavigate();
  const [totalQuestions, setTotalQuestions] = useState(quizData.totalQuestions);
  const currentQuestion = quizData.currentQuestion; // This should be dynamically updated based on the current question index

  const handleNextClick = () => {
    if (heatingReceived) {
      updateQuizData({ heatingReceived, heatingType, totalQuestions, currentQuestion: currentQuestion+1 });
      if (heatingReceived == 'yes' && heatingType == 'electricity') {
        navigate('/quiz/elec-heating-consumption');
      }
      else if (heatingReceived == 'yes') {
        navigate('/quiz/heating-consumption');
      }
      else {
        navigate('/quiz/total-expenses');
      }

    }
  };

  const handleBackClick = () => {
      updateQuizData({ currentQuestion: quizData.currentQuestion-1 });
      navigate('/quiz/renewable-electricity');   
  }

  const handleClickButton = (e) => {
    setHeatingReceived(e.target.value);
  if (e.target.value == 'yes') {
      setTotalQuestions(quizData.totalQuestions);
    }
    else {
      setTotalQuestions(currentQuestion);
    }
  }

  const handleHeatingType = (e) => {
    setHeatingType(e.target.value);
  }
  

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <div className="progress-wrapper sticky-top mb-3">
              <div className="d-flex justify-content-between mb-1">
                <span>{currentQuestion} / {totalQuestions}</span>
              </div>
              <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
            </div>
            <h6 className="sub-title mb-1">From {quizData.startDate} to {quizData.endDate}</h6>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>Did your company receive heating?</h2>
                <p className="text-muted fs-17">Tip: Your landlord or office manager would know this</p>
                <div>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <label>
                        <Form.Check
                            type="radio"
                            value="yes"
                            checked={heatingReceived === 'yes'}
                            onChange={(e) => handleClickButton(e)}
                            label="Yes"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <label>
                        <Form.Check
                            type="radio"
                            value="no"
                            checked={heatingReceived === 'no'}
                            onChange={(e) => handleClickButton(e)}
                            label="No"
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  {heatingReceived === 'yes' && (
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <label htmlFor="heatingTypeSelect">Select the type of heating received:</label>
                          <Form.Select
                            id="heatingTypeSelect"
                            value={heatingType}
                            onChange={(e) => handleHeatingType(e)}
                          >
                            <option value="">Select an option</option>
                            <option value="gas">Gas</option>
                            <option value="oil">Oil</option>
                            <option value="electricity">Electricity</option>
                          </Form.Select>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </div>
                <div className="mt-2">
                <button onClick={handleBackClick} className="btn btn-primary me-4">
                  Back
                </button>
                <button onClick={handleNextClick} disabled={!heatingReceived || (heatingReceived === 'yes' && !heatingType)} className="btn btn-primary">
                  Next
                </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col lg={6}>
              <div className="me-4">
                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                <p className="text-muted fs-17">
                  Different heating sources produce varying amounts of emissions, therefore it is important to know the heating source.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HeatingReceivedPage;
