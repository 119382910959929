import React from "react";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck, faRectangleXmark, faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/integration.css'
const ProcessCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={4} md={6}>
        <div className="custom-card text-left rounded">
          <div className="icon-container mb-3">
            <FontAwesomeIcon icon={props.icon} size="2x" style={{ color: '#333' }} />
          </div>
          <h5 className="fs-18 card-title">{props.step}</h5>
          <p className="text-muted card-description">{props.caption}</p>
        </div>
      </Col>
    </React.Fragment>
  );
}

// Process Data

const ProcessData = [
  {
    step: "Engage your users",
    caption: "Empower your users with easy access to climate action. Enable them to back certified carbon projects and technologies seamlessly.",
  },
  {
    number: "",
    step: "Streamline your carbon initiatives",
    caption: "Automate your carbon strategy and save time by integrating carbon credits into your workflow. Purchase credits instantly to offset emissions.",
  },
  {
    number: "03",
    step: "Tailor a smooth experience",
    caption: "Customize c-mass's simple purchasing flow with your own branding and UI. Simplify the process so users don't have to worry about multiple choices.",
  }
];


const APIFeaturesAR = () => {
  return (
<React.Fragment>
  <section
    className="bg-process section"
    id="process"
    style={{ backgroundColor: "#c1d7c7" }}
    dir="rtl"
  >
    <Container>
      <Row className="justify-content-center">
        <Col lg={7}>
          <div className="header-title text-center">
            <h3>ميزات واجهة برمجة التطبيقات</h3>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 pt-2">
        {/* Features Component Start */}
        <Col lg={4} md={6}>
          <div className="custom-card text-right rounded">
            <div className="icon-container mb-3">
              <FontAwesomeIcon icon={faListCheck} size="2x" style={{ color: "#333" }} />
            </div>
            <h5 className="fs-18 card-title">محفظة متنوعة</h5>
            <p className="text-muted card-description">
              قدم لمستخدميك حلولًا مؤتمتة للتأثير المناخي مع محفظة مدعومة علميًا وموثوقة، مما يلغي الحاجة إلى اختيار المشاريع بشكل متكرر.
            </p>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="custom-card text-right rounded">
            <div className="icon-container mb-3">
              <FontAwesomeIcon icon={faRectangleXmark} size="2x" style={{ color: "#333" }} />
            </div>
            <h5 className="fs-18 card-title">إلغاء الطلب</h5>
            <p className="text-muted card-description">
              هل قمت بخطأ؟ يمكنك إلغاء الطلبات بسهولة كما قمت بوضعها.
            </p>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div className="custom-card text-right rounded">
            <div className="icon-container mb-3">
              <FontAwesomeIcon icon={faShareFromSquare} size="2x" style={{ color: "#333" }} />
            </div>
            <h5 className="fs-18 card-title">شارك التأثير بعلامتك التجارية</h5>
            <p className="text-muted card-description">
              قم بسهولة بدمج معلومات أرصدة الكربون والشهادات وتفاصيل المشاريع في منتجك لعرض التأثير تحت علامتك التجارية الخاصة.
            </p>
          </div>
        </Col>
        {/* Features Component End */}
      </Row>
    </Container>
  </section>
</React.Fragment>

  );
}

export default APIFeaturesAR;
