import React, { useState } from "react";
import "./PricingPage.css";
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { useNavigate } from "react-router-dom";

const PricingPage = () => {
    const [faqOpen, setFaqOpen] = useState({});
    const navigate = useNavigate();

    const toggleFaq = (index) => {
        setFaqOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleGetStarted = () => {
        navigate("/login");
    };

    return (
        <>
            <NavbarSubpage />
            <div className="pricing-page">
                {/* Pricing Section */}
                <section className="pricing-section">
                    <h2>Simple, transparent pricing</h2>
                    <p>Pay for what you use and nothing else.</p>
                    <div className="pricing-cards">
                        <div className="pricing-card">
                            <h3>Standard</h3>
                            <p>
                                Purchase one of our <a href="/">carefully curated portfolios</a>{" "}
                                and get started immediately
                            </p>
                            <h1>
                                SAR80 <span>/ tonne</span>
                            </h1>
                            <button className="btn-primary" onClick={handleGetStarted}>
                                Get Started
                            </button>
                            <h4>Includes:</h4>
                            <ul>
                                <li>Pay as you go: no upfront or platform fees</li>
                                <li>API availability</li>
                                <li>Detailed project data</li>
                                <li>Customizable public impact page</li>
                                <li>Options that meet Climate Active and Change Climate requirements</li>
                            </ul>
                        </div>
                        <div className="pricing-card">
                            <h3>Custom</h3>
                            <p>Talk to us to customize a portfolio that fits your needs.</p>
                            <button className="btn-secondary">Contact Us</button>
                            <h4>Everything in Standard plus:</h4>
                            <ul>
                                <li>Volume pricing</li>
                                <li>Custom project selection</li>
                                <li>Flexible purchasing options</li>
                                <li>24/7 advisory support</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* FAQ Section */}
                <section className="faq-section">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq-category">
                        <h3>General</h3>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[1]} onClick={() => toggleFaq(1)}>
                                What does the platform do?
                            </button>
                            {faqOpen[1] && (
                                <p>
                                    We provide access to verified carbon credits and a simple
                                    platform for businesses to offset their emissions.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[2]} onClick={() => toggleFaq(2)}>
                                How does it work?
                            </button>
                            {faqOpen[2] && (
                                <p>
                                    Our platform simplifies the process of selecting, purchasing,
                                    and managing carbon credits, with full transparency.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="faq-category">
                        <h3>Carbon Credits</h3>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[4]} onClick={() => toggleFaq(4)}>
                                What is a carbon credit?
                            </button>
                            {faqOpen[4] && (
                                <p>
                                    A carbon credit represents the reduction or removal of one metric tonne of carbon dioxide from the atmosphere.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[5]} onClick={() => toggleFaq(5)}>
                                Why should I purchase carbon credits?
                            </button>
                            {faqOpen[5] && (
                                <p>
                                    Carbon credits allow companies to take responsibility for emissions they cannot currently reduce. They act as a bridge to future decarbonization goals.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="faq-category">
                        <h3>Pricing</h3>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[7]} onClick={() => toggleFaq(7)}>
                                How does pricing work?
                            </button>
                            {faqOpen[7] && (
                                <div>
                                    <p>Pricing depends on the portfolio you choose:</p>
                                    <ul>
                                        <li>No minimum order requirements</li>
                                        <li>No platform fees</li>
                                        <li>Fractional tonne purchases available</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="faq-item">
                            <button aria-expanded={faqOpen[8]} onClick={() => toggleFaq(8)}>
                                What fees do you charge?
                            </button>
                            {faqOpen[8] && (
                                <p>
                                    We charge a flat rate per tonne. No additional platform or transaction fees.
                                </p>
                            )}
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
};

export default PricingPage;
