import React, { useState } from "react";
import "./PricingPage.css";
import NavbarSubpageAR from "../../Layout/NavbarSubpageAR";
import { useNavigate } from "react-router-dom";

const PricingPageAR = () => {
    const [faqOpen, setFaqOpen] = useState({});
    const navigate = useNavigate();

    const toggleFaq = (index) => {
        setFaqOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleGetStarted = () => {
        navigate("/login");
    };

    return (
        <>
            <NavbarSubpageAR />
            <div className="pricing-page" dir="rtl">
                {/* قسم التسعير */}
                <section className="pricing-section-ar" dir="rtl">
                    <h2>تسعير بسيط وشفاف</h2>
                    <p>ادفع فقط لما تستخدمه ولا شيء آخر.</p>
                    <div className="pricing-cards">
                        <div className="pricing-card rtl" dir="rtl">
                            <h3 dir="rtl">الخطة القياسية</h3>
                            <p>
                                قم بشراء واحدة من{" "}
                                <a href="/">محافظنا المختارة بعناية</a> وابدأ فوراً.
                            </p>
                            <h1>
                                80 ريال <span>/ للطن</span>
                            </h1>
                            <button className="btn-primary" onClick={handleGetStarted}>
                                ابدأ الآن
                            </button>
                            <h4>يشمل:</h4>
                            <ul>
                                <li>الدفع حسب الاستخدام: بدون رسوم مقدمة أو رسوم منصة</li>
                                <li>توفر واجهة برمجة التطبيقات (API)</li>
                                <li>بيانات مشروع مفصلة</li>
                                <li>صفحة تأثير عامة قابلة للتخصيص</li>
                                <li>خيارات تتوافق مع متطلبات Climate Active وChange Climate</li>
                            </ul>
                        </div>
                        <div className="pricing-card">
                            <h3>الخطة المخصصة</h3>
                            <p>تحدث إلينا لتخصيص محفظة تناسب احتياجاتك.</p>
                            <button className="btn-secondary">تواصل معنا</button>
                            <h4>كل ما في الخطة القياسية بالإضافة إلى:</h4>
                            <ul>
                                <li>تسعير الكميات الكبيرة</li>
                                <li>اختيار المشاريع المخصصة</li>
                                <li>خيارات شراء مرنة</li>
                                <li>دعم استشاري على مدار الساعة</li>
                            </ul>
                        </div>
                    </div>
                </section>

                {/* قسم الأسئلة الشائعة */}
                <section className="faq-section">
                    <h2>الأسئلة الشائعة</h2>
                    <div className="faq-category">
                        <h3>عام</h3>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(1)}>ما هو دور المنصة؟</button>
                            {faqOpen[1] && (
                                <p>
                                    نحن نوفر الوصول إلى أرصدة الكربون المعتمدة ومنصة بسيطة للشركات لتعويض انبعاثاتها.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(2)}>كيف تعمل المنصة؟</button>
                            {faqOpen[2] && (
                                <p>
                                    تعمل منصتنا على تبسيط عملية اختيار وشراء وإدارة أرصدة الكربون مع توفير الشفافية الكاملة.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(3)}>كيف أبدأ؟</button>
                            {faqOpen[3] && (
                                <p>
                                    تواصل مع فريقنا للبدء. سنرشدك خلال كل خطوة من العملية.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="faq-category">
                        <h3>أرصدة الكربون</h3>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(4)}>ما هي أرصدة الكربون؟</button>
                            {faqOpen[4] && (
                                <p>
                                    أرصدة الكربون تمثل تقليل أو إزالة طن متري واحد من ثاني أكسيد الكربون من الغلاف الجوي.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(5)}>لماذا يجب علي شراء أرصدة الكربون؟</button>
                            {faqOpen[5] && (
                                <p>
                                    أرصدة الكربون تساعد الشركات على تحمل مسؤولية الانبعاثات التي لا يمكنها تقليلها حالياً. إنها تعمل كجسر نحو أهداف إزالة الكربون المستقبلية.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(6)}>كيف تضمن المنصة جودة المشاريع؟</button>
                            {faqOpen[6] && (
                                <p>
                                    جميع المشاريع يتم اختيارها بعناية والتحقق منها بواسطة أطراف مستقلة لضمان الجودة والتأثير العالي.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="faq-category">
                        <h3>التسعير</h3>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(7)}>كيف يعمل التسعير؟</button>
                            {faqOpen[7] && (
                                <div>
                                    <p>يعتمد التسعير على المحفظة التي تختارها:</p>
                                    <ul>
                                        <li>لا توجد متطلبات طلبات دنيا</li>
                                        <li>بدون رسوم منصة</li>
                                        <li>إمكانية شراء كميات جزئية</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(8)}>ما هي الرسوم التي تفرضها المنصة؟</button>
                            {faqOpen[8] && (
                                <p>
                                    نحن نفرض سعر ثابت لكل طن. لا توجد رسوم منصة إضافية أو رسوم معاملات.
                                </p>
                            )}
                        </div>
                        <div className="faq-item">
                            <button onClick={() => toggleFaq(9)}>ما طرق الدفع التي تقبلونها؟</button>
                            {faqOpen[9] && (
                                <p>
                                    نحن نقبل بطاقات الائتمان وتحويلات ACH، بالإضافة إلى الفواتير والمشتريات داخل التطبيق.
                                </p>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default PricingPageAR;
