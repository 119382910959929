import React from "react";
import "../../assets/css/CompanyPage.css";
import NavbarSubpage from "../../Layout/NavbarSubpage";
import Team from '../../components/Team';

const CompanyPage = () => {
    return (
        <React.Fragment>
            <NavbarSubpage />
            <section className="bg-home5" id="home">
                <div className="company-page">
                    {/* About Us Section */}
                    <section className="about-us-section">
                        <div className="about-us-content">
                            <h4>About us</h4>
                            <h1>
                                Empowering businesses to take meaningful action for a sustainable
                                future
                            </h1>
                            <p>
                                We believe that everyone has a role to play in reducing the carbon
                                footprint and addressing climate change. However, navigating the
                                world of carbon credits can be complex and overwhelming, especially
                                for those new to the space. That’s why we’ve created an easy and
                                transparent platform that simplifies the process.
                            </p>
                            <p>
                                Our platform provides businesses with access to verified and
                                impactful carbon credits, enabling them to offset emissions and
                                contribute to global sustainability goals. By combining simplicity
                                with robust science, we’re helping companies of all sizes take
                                action and create a tangible climate impact.
                            </p>
                        </div>
                    </section>

                    {/* Our Values Section */}
                    <section className="values-section">
                        <div className="values-content">
                            <h2>Our Values</h2>
                            <p>
                                Our core values drive everything we do. These principles guide our
                                decision-making, shape our goals, and form the foundation of our
                                culture.
                            </p>
                            <div className="values-cards">
                                <div className="value-card">
                                    <h3>Drive Positive Change</h3>
                                    <p>
                                        Every decision we make is aimed at creating a better world. Our
                                        focus is on enabling businesses to take meaningful and scalable
                                        steps toward a sustainable future.
                                    </p>
                                </div>
                                <div className="value-card">
                                    <h3>Embrace Growth</h3>
                                    <p>
                                        We are committed to constant learning and improvement. Every
                                        challenge is an opportunity to grow and deliver better solutions
                                        for our clients.
                                    </p>
                                </div>
                                <div className="value-card">
                                    <h3>Collaborate for Impact</h3>
                                    <p>
                                        Teamwork is at the heart of everything we do. By working
                                        together and fostering collaboration, we amplify our impact and
                                        achieve shared goals.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Team />
                </div>
            </section>
        </React.Fragment>
    );
};

export default CompanyPage;
