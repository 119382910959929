import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import ProjectsSupportedCard from '../../components/ProjectsSupportedCard';
import UserContext from "../../contexts/user/UserContext";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/offset.css"; // Create and import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faFile, faBullhorn, faTree, faCar, faHome, faPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import logo from '../../assets/images/nz-logo.png';
import bg from '../../assets/images/subtle-prism.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ct from '../../assets/images/ct.png';
import QRCode from 'qrcode';

const Offset = () => {
    const { user } = useContext(UserContext);
    const { isSandboxMode } = useContext(ModeContext);
    const [orders, setOrders] = useState([]);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const { company } = useOutletContext();
    const [loading, setLoading] = useState(true);

    const handleCertificateClick = async (company, orders) => {
        try {
            const projectIds = orders?.lastOrderDetails?.projectRecords.map(record => record.projectId);
            if (!projectIds || projectIds.length === 0) {
                console.error('No projects found');
                return;
            }
            // Fetch project records by their IDs

            // Extract project IDs from the project records

            // Fetch projects using the project IDs
            const projectsResponse = await http.post(`${API_ENDPOINTS.PROJECTS_BY_IDS}?isSandbox=${isSandboxMode}`, { projectIds });

            // Store the project details
            setProjects(projectsResponse.data.projects);

            // Generate the certificate
            const certificateContainer = document.createElement('div');
            certificateContainer.id = 'certificate';
            certificateContainer.style.width = '1200px';
            certificateContainer.style.height = '800px';
            certificateContainer.style.background = '#f0f8f5';
            certificateContainer.style.padding = '50px';
            certificateContainer.style.border = '10px solid #3CB371';
            certificateContainer.style.position = 'relative';
            certificateContainer.style.fontFamily = 'Arial, sans-serif';
            certificateContainer.style.textAlign = 'center';
            certificateContainer.style.backgroundImage = bg;
            certificateContainer.style.backgroundSize = 'contain';

            const floralBanner = document.createElement('div');
            floralBanner.style.background = '#3CB371';
            floralBanner.style.color = '#fff';
            floralBanner.style.padding = '20px';
            floralBanner.style.fontSize = '24px';
            floralBanner.innerHTML = `<h1 style="margin: 0;">CERTIFICATE OF VOLUNTARY CARBON ACTION</h1>`;

            const centerContent = document.createElement('div');
            centerContent.style.background = '#ffffff';
            centerContent.style.padding = '30px';
            centerContent.style.margin = '50px auto';
            centerContent.style.borderRadius = '20px';
            centerContent.style.boxShadow = '0 0 30px rgba(0, 0, 0, 0.2)';
            centerContent.innerHTML = `
                <h2 style="font-size: 20px; color: #3CB371;">Issued to:</h2>
                <h3 style="font-size: 28px; color: #333;">${company.name}</h3>
                <h2 style="font-size: 32px; color: #333;">${orders.lastOrderDetails.creditsPurchased} kg of CO₂e</h2>
                <p style="font-size: 16px; color: #666;"><strong>Order #:</strong> ${orders.lastOrderDetails.orderNumber || 'N/A'}</p>
                <p style="font-size: 16px; color: #666;"><strong>Purchased on:</strong> ${orders.lastOrderDetails.createdOn.split('T')[0]}</p>
                <p style="font-size: 16px; color: #666;"> <img src=${logo} alt="logo" width="100" height="100">
            </p>`;

            // Generate the dynamic footer content based on the projects data
            const certificateFooter = document.createElement('div');
            certificateFooter.style.position = 'absolute';
            certificateFooter.style.bottom = '40px';
            certificateFooter.style.width = '100%';
            certificateFooter.style.textAlign = 'center';
            certificateFooter.style.color = '#3CB371';
            certificateFooter.style.fontSize = '14px';

            // Create the footer's inner HTML by looping through the projects array
            let footerContent = '<div style="display: flex; justify-content: space-around; padding: 0 80px;">';
            projectsResponse.data.projects.forEach(project => {
                footerContent += `
                    <p><strong>${project.name}</strong><br />${project.description}</p>
                `;
            });
            footerContent += '</div>';

            certificateFooter.innerHTML = footerContent;

            certificateContainer.appendChild(floralBanner);
            certificateContainer.appendChild(centerContent);
            certificateContainer.appendChild(certificateFooter);
            document.body.appendChild(certificateContainer);

            html2canvas(certificateContainer).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('l', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save("cc--certificate.pdf");
                document.body.removeChild(certificateContainer);
            });
        } catch (error) {
            console.error('Error generating certificate:', error);
        }
    };

    const generateCertificate = async (company, orders) => {

        try {
            const projectIds = orders?.lastOrderDetails?.projectRecords.map(record => record.projectId);
            if (!projectIds || projectIds.length === 0) {
                console.error('No projects found');
                return;
            }
            // Fetch project records by their IDs

            // Extract project IDs from the project records

            // Fetch projects using the project IDs
            const projectsResponse = await http.post(`${API_ENDPOINTS.PROJECTS_BY_IDS}?isSandbox=${isSandboxMode}`, { projectIds });

            // Store the project details
            setProjects(projectsResponse.data.projects);

            const doc = new jsPDF();

            // Load the background image (optional)
            const img = new Image();
            img.src = ct;

            let imgWidth, imgHeight;

            // Wait for the image to load
            await new Promise(resolve => {
                img.onload = () => {
                    // Set image dimensions
                    imgWidth = img.width;
                    imgHeight = img.height;

                    // Set the PDF page size to match the image dimensions
                    doc.internal.pageSize.width = imgWidth * 0.264583; // Convert px to mm
                    doc.internal.pageSize.height = imgHeight * 0.264583;

                    // Add the image, centering it on the page
                    doc.addImage(img, 'PNG', 0, 0, imgWidth * 0.264583, imgHeight * 0.264583);

                    resolve();
                };
            });

            doc.setFont('Helvetica');
            doc.setFontSize(100);
            doc.setTextColor('#222222');

            doc.text(`${orders.lastOrderDetails.createdOn.split('T')[0]}`, 250, 58, { align: "center" });
            doc.text(`${company.name}`, 120, 338, { align: "center" });
            doc.text(`${orders.lastOrderDetails.creditsPurchased} kg of CO₂e`, 180, 465, { align: "center" });

            let projs_name = '';
            let projs_type = '';
            let proj_registry = '';
            let proj_location = '';

            projectsResponse.data.projects.forEach((project, index) => {
                if ((index + 1) == projectsResponse.data.projects.length) {
                    projs_name += `${project.name}`;
                    projs_type += `${project.type}`;
                    proj_registry += `${project.carbonRegistry.name}`;
                    proj_location += `${project.location}`;
                }
                else {
                    projs_name += `${project.name}, `;
                    projs_type += `${project.type}, `;
                    proj_registry += `${project.carbonRegistry.name}, `;
                    proj_location += `${project.location}, `;
                }

            });
            doc.text(`${projs_name}`, 70, 595, { align: "left" });
            doc.text(`${projs_type}`, 70, 725, { align: "left" });
            doc.text(`${proj_registry}`, 840, 725, { align: "left" });
            doc.text(`${proj_location}`, 70, 855, { align: "left" });
            doc.text(`c-mass`, 840, 855, { align: "left" });

            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(200);
            doc.setTextColor('#2cc9a2');
            doc.text(`${orders.lastOrderDetails.creditsPurchased}`, 565, 945, { align: "center" });

            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(80);
            doc.setTextColor('#0096FF');
            doc.textWithLink(`${orders.lastOrderDetails.orderNumber}`, 215, 990, { url: 'http://www.google.com' });

            doc.setTextColor('#222222');
            doc.text(`${proj_registry}`, 250, 1030, { align: "left" });

            // Generate the QR code and add it to the PDF 
            const qrDataUrl = await QRCode.toDataURL(`https://c-mass.co/order-detail/${orders.lastOrderDetails._id}`, { width: 100 });
            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(15);
            doc.setTextColor('#222222');



            // Position text and QR code to fit within the image dimensions
            doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.364583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);

            // Add a new page if this is not the last code

            // Save the PDF with all promo codes in one file
            doc.save('cc--certificate.pdf.pdf');

        } catch (error) {

        }

    };

    const handleAPIDocsClick = () => {
        window.open("https://c-mass.mintlify.app/introduction", "_blank");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS_SUMMERY}/${company._id}?isSandbox=${isSandboxMode}`);
                setOrders(ordersResponse.data);

            } catch (error) {
                console.error("Error fetching data:", error);
                setOrders([]);
            } finally {
                setLoading(false);
            }
        };



        fetchData();
    }, [user, company._id, isSandboxMode]);

    const handlePurchaseClick = () => {
        navigate('/profile/purchase');
    }

    const handleCertificate = () => {
        generateCertificate(company, orders);
    }

    const handleImpactClick = () => {
        navigate('/profile/my-impact', { state: { orders } });
    }

    const handleOrderDetailClick = () => {
        navigate(`/profile/order/${orders?.lastOrderDetails?._id}`);
    }

    const handleViewAllOrdersClick = () => {
        navigate(`/profile/orders`);
    }

    const handleMetricClick = () => {
        navigate('/profile/my-impact')
    }

    return (
        <Container className="dashboard">
            {/* <Row className="mb-4">
                <Col>
                    <button color="primary" className="purchase-credits-btn" onClick={handlePurchaseClick}>Purchase credits</button>
                </Col>
            </Row>
            <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} /> */}
            <Row>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none', minWidth: '50px' }}>
                        {loading ? <Spinner /> : (
                            <CardBody>
                                <h5 className="card-title">Impact Metrics</h5>
                                <div className="icon-text">
                                    <p className="total-description"><FontAwesomeIcon icon={faLeaf} style={{ color: "#00b295" }} /> Total amount of carbon credits purchased</p>
                                </div>
                                <h1 className="impact-value">{orders?.totalCreditsPurchased ? orders?.totalCreditsPurchased : 0} kg</h1>
                                <Row>
                                    <Col xs={6}>
                                        <div className="metric-detail">
                                            <FontAwesomeIcon icon={faTree} />
                                            <p>Trees planted</p>
                                            <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.022).toFixed(2) : 0}</h3>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="metric-detail">
                                            <FontAwesomeIcon icon={faCar} />
                                            <p>Cars off road*</p>
                                            <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.00021).toFixed(2) : 0}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <div className="metric-detail">
                                            <FontAwesomeIcon icon={faHome} />
                                            <p>Homes' energy*</p>
                                            <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.00022).toFixed(2) : 0}</h3>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="metric-detail">
                                            <FontAwesomeIcon icon={faPlane} />
                                            <p>Flights RUH-LHR</p>
                                            <h3>{orders?.totalCreditsPurchased ? ((orders?.totalCreditsPurchased) * 0.0011).toFixed(2) : 0}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <p className="footnote">*for one year</p>
                                <button color="primary" className="mid-button" onClick={handleImpactClick}>All Metrics</button>
                            </CardBody>
                        )}
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        {loading ? <Spinner /> : (
                            <CardBody>
                                <h5 className="card-title">Orders</h5>
                                <div className="orders-header">
                                    <p><FontAwesomeIcon icon={faFile} size="lg" style={{ color: "#63E6BE" }} />
                                        <span className="orders-text">Orders Fulfilled</span></p>
                                </div>
                                <h1 className="order-count">{orders.totalOrderAmount ? orders.totalOrderAmount : 0}</h1>

                                {/* Most Recent Order Section */}
                                {orders?.lastOrderDetails ? <div className="recent-order-section">
                                    <h6 className="recent-order-title">Most Recent Order</h6>
                                    <Row>
                                        <Col xs={20}>
                                            <p><span className="label">Amount CO₂e:</span> {orders?.lastOrderDetails?.creditsPurchased} kg</p>
                                            <p><span className="label">Price:</span> SAR {(orders?.lastOrderDetails?.price_sar_halalas / 100).toFixed(2)}</p>
                                            <p><span className="label">Placed On:</span> {orders?.lastOrderDetails?.createdOn.split('T')[0]}</p>
                                            <Button color="link" className="view-details-btn" onClick={handleOrderDetailClick}>View Order Details</Button>
                                            <Button color="link" className="view-certificate-btn" onClick={handleCertificate}>View certificate</Button>
                                        </Col>
                                        {/*<Col xs={4}>
                            <img 
                                src="/path-to-certificate-image.png" 
                                alt="Certificate"
                                className="certificate-image" 
                            />
                        </Col>*/}
                                    </Row>
                                </div> :
                                    <div className="recent-order-section">
                                        <h6 className="recent-order-title">No Recent Order</h6>

                                    </div>}
                                <button className="mid-button" onClick={handleViewAllOrdersClick}>All Orders</button>
                            </CardBody>
                        )}
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col lg={4} md={6} sm={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        <CardBody>
                            <div className="icon-text">
                                <p className="sh"><FontAwesomeIcon size="xl" icon={faBullhorn} style={{ color: "#e05910" }} />
                                    <span style={{ fontSize: 'x-large', marginLeft: '5px', fontWeight: 'bold' }}>Share</span></p>
                            </div>
                            <p>Tell the world about your climate impact.</p>
                            <button className="small-button" onClick={handleImpactClick}>View impact page</button>
                            <button className="small-button">Settings</button>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={4} md={6} sm={12} className="mb-4">
                    <Card className="api-card same-size-card-bottom card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        <CardBody>
                            <h3>c-mass API</h3>
                            <p>Complete the API setup to begin using the live API.</p>
                            <Button color="link" className="api-docs-btn" onClick={handleAPIDocsClick}>Explore the API documentation.</Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4} md={6} sm={12} className="mb-4">
                    <ProjectsSupportedCard />
                </Col>
            </Row>
        </Container>
    );
};

export default Offset;
