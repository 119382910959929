import React from 'react';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Button } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const SectionOverviewPage = () => {
  const navigate = useNavigate()

  const handleNextClick = () => {
    navigate('/quiz/before-start');
  };

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <h2>Emissions estimates are all about the data</h2>
                <p>Here’s what you’ll need to gather:</p>
                <ul>
                  <li>Number of employees, size of facilities, annual revenue</li>
                  <li>Heating costs and information about company-owned vehicles</li>
                  <li>Company expenses in areas like business travel, transport / freight, capital goods and more</li>
                </ul>
                <button onClick={handleNextClick} className="btn btn-primary mt-2">Next</button>

              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default SectionOverviewPage;
