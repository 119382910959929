import React from "react";
import { Button, Col, Container, Row } from "reactstrap";

const CTAAR = () => {
  return (
    <React.Fragment>
      <section className="bg-cta" dir="rtl" style={{ textAlign: "right" }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="header-title text-center">
                <h2>كن جزءًا من مجتمع أعمال c-mass اليوم</h2>
                <p className="title-desc text-muted mt-3">
                  يمكن لـ c-mass توفير كل ما تحتاجه لتحقيق الامتثال وإحداث تأثير إيجابي.
                </p>
                <div className="mt-4">
                  <Button color="success" href="/#" className="mt-2">
                    ابدأ الآن
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>

  );
}

export default CTAAR;
