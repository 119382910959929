import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Table, Button, Card, CardBody } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faFile } from '@fortawesome/free-solid-svg-icons';
import UserContext from "../../contexts/user/UserContext";
import { ModeContext } from '../../contexts/mode/ModeContext';
import { useNavigate, useOutletContext } from 'react-router-dom';

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { company } = useOutletContext();
  const navigate = useNavigate();
  const { isSandboxMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS}/${user.companyId}?isSandbox=${isSandboxMode}`);
        setOrders(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, isSandboxMode]);

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case 'placed':
        return 'bg-warning text-dark'; // Yellow badge for placed
      case 'canceled':
        return 'bg-danger'; // Red badge for canceled
      case 'fulfilled':
        return 'bg-success'; // Green badge for fulfilled
      default:
        return 'bg-secondary'; // Default badge if status is unknown
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/profile/order/${orderId}`);
  };

  return (
    <Container className="dashboard">
      <h2 className="mb-4" style={{ fontWeight: 'bold' }}>Order History</h2>
      <Row>
        <Col lg={3} md={4} sm={6} className="mb-4">
          <Card className="info-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none', padding: '15px' }}>
            {loading ? <Spinner /> : <CardBody>
              <FontAwesomeIcon icon={faLeaf} size="2x" style={{ color: "#63E6BE" }} />
              <h3 style={{ fontWeight: 'bold', marginTop: '10px' }}>{orders.totalCreditsPurchased ? (orders.totalCreditsPurchased).toFixed(0) : 0} kg</h3>
              <p>CO₂e offset</p>
            </CardBody>}
          </Card>
        </Col>
        <Col lg={3} md={4} sm={6} className="mb-4">
          <Card className="info-card" style={{ borderRadius: '25px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none', padding: '15px' }}>
            {loading ? <Spinner /> : <CardBody >
              <FontAwesomeIcon icon={faFile} size="2x" />
              <h3 style={{ fontWeight: 'bold', marginTop: '10px' }}>{orders.totalOrderAmount ? orders.totalOrderAmount : 0}</h3>
              <p>Orders fulfilled</p>
            </CardBody>}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table className="subaccounts-table" responsive>
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>STATUS</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>PLACED ON</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Spinner />
                  </td>
                </tr>
              ) : orders.orders?.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No orders found.
                  </td>
                </tr>
              ) : (
                orders.orders?.map((order) => (
                  <tr key={order._id}>
                    <td>
                      <Button color="link" onClick={() => handleOrderClick(order._id)}><strong>{order._id}</strong></Button>
                    </td>
                    <td><span className={`badge ${getStatusBadgeClass(order.status)}`}>
                        {order.status.toUpperCase() || '?'}
                      </span></td>
                    <td>{order.kg_amount || 0} kg</td>
                    <td>{order.description}</td>
                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default OrdersPage;
