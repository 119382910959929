import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/p-http";
import phttp from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/orderPage.css"; // Create and import your CSS file
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faWeightScale } from '@fortawesome/free-solid-svg-icons';
import cMassLogo from '../../assets/images/nz-logo.png'; // Replace with your C-Mass logo path
import logo from '../../assets/images/nz-logo.png';
import bg from '../../assets/images/subtle-prism.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ct from '../../assets/images/ct.png';
import QRCode from 'qrcode';

const PublicOrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isSandboxMode } = useContext(ModeContext);

  const generateCertificate = async () => {

    try {
        const projectIds = order.projectRecords.map(record => record.projectId);
        if (!projectIds || projectIds.length === 0) {
            console.error('No projects found');
            return;
        }
        // Fetch project records by their IDs

        // Extract project IDs from the project records

        // Fetch projects using the project IDs
        const projectsResponse = await phttp.post(`${API_ENDPOINTS.PROJECTS_BY_IDS}?isSandbox=${isSandboxMode}`, { projectIds });

        // Store the project details
        //setProjects(projectsResponse.data.projects);

        const doc = new jsPDF();

        // Load the background image (optional)
        const img = new Image();
        img.src = ct;

        let imgWidth, imgHeight;

        // Wait for the image to load
        await new Promise(resolve => {
            img.onload = () => {
                // Set image dimensions
                imgWidth = img.width;
                imgHeight = img.height;

                // Set the PDF page size to match the image dimensions
                doc.internal.pageSize.width = imgWidth * 0.264583; // Convert px to mm
                doc.internal.pageSize.height = imgHeight * 0.264583;

                // Add the image, centering it on the page
                doc.addImage(img, 'PNG', 0, 0, imgWidth * 0.264583, imgHeight * 0.264583);

                resolve();
            };
        });

        doc.setFont('Helvetica');
        doc.setFontSize(100);
        doc.setTextColor('#222222');

        doc.text(`${order.createdOn.split('T')[0]}`, 250, 58, { align: "center" });
        if(order?.customer) {
          doc.text(`${order?.customer}`, 260, 338, { align: "center" });
        }
        else {
          doc.text(`${order.company?.name}`, 120, 338, { align: "center" });
        }
        doc.text(`${order.creditsPurchased} kg of CO₂e`, 180, 465, { align: "center" });

        let projs_name = '';
        let projs_type = '';
        let proj_registry = '';
        let proj_location = '';

        projectsResponse.data.projects.forEach((project, index) => {
            if ((index + 1) == projectsResponse.data.projects.length) {
                projs_name += `${project.name}`;
                projs_type += `${project.type}`;
                proj_registry += `${project.carbonRegistry.name}`;
                proj_location += `${project.location}`;
            }
            else {
                projs_name += `${project.name}, `;
                projs_type += `${project.type}, `;
                proj_registry += `${project.carbonRegistry.name}, `;
                proj_location += `${project.location}, `;
            }

        });
        doc.text(`${projs_name}`, 70, 595, { align: "left" });
        doc.text(`${projs_type}`, 70, 725, { align: "left" });
        doc.text(`${proj_registry}`, 840, 725, { align: "left" });
        doc.text(`${proj_location}`, 70, 855, { align: "left" });
        doc.text(`c-mass`, 840, 855, { align: "left" });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(200);
        doc.setTextColor('#2cc9a2');
        doc.text(`${order.creditsPurchased}`, 565, 945, { align: "center" });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(80);
        doc.setTextColor('#0096FF');
        doc.textWithLink(`${order.orderNumber}`, 215, 990, { url: 'http://www.google.com' });

        doc.setTextColor('#222222');
        doc.text(`${proj_registry}`, 250, 1030, { align: "left" });

        // Generate the QR code and add it to the PDF 
        const qrDataUrl = await QRCode.toDataURL(`https://c-mass.co/order-detail/${order._id}`, { width: 100 });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(15);
        doc.setTextColor('#222222');



        // // Position text and QR code to fit within the image dimensions
        // doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.264583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);
        // Position text and QR code to fit within the image dimensions
        doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.364583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);

        // Add a new page if this is not the last code

        // Save the PDF with all promo codes in one file
        doc.save('cc--certificate.pdf.pdf');

    } catch (error) {

    }

};

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PUBLIC_ORDER_DETAILS}/${orderId}?isSandbox=${isSandboxMode}`);
        setOrder(response.data.order);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId, isSandboxMode]);

  const handleCertificate = () => {
    generateCertificate();
}

  return (
    <div className="dashboard" style={{ backgroundColor: '#f0faf4' }}>
      {loading ? <Container><Spinner /></Container> : (
        <Container >
          <Row className="order-header mt-4">
            <Col md="6">
            <img src={cMassLogo} alt="Powered by C-Mass" style={{ maxWidth: '80px', marginTop: '5px' }} />
              <p style={{ fontSize: '10px', marginTop: '5px', color: '#666' }}>Powered by c-mass</p>
              <h4 className="order-id">Order ID: <span className="order-id-value">{order.orderNumber}</span></h4>
              <h1 className="order-title">Order Details</h1>
              <ul className="order-details-list">
                <li><strong>Placed On:</strong> {new Date(order.createdAt).toLocaleString()}</li>
                <li><strong>Amount:</strong> {order.creditsPurchased} kg</li>
                <li><strong>Portfolio:</strong> {order.portfolio?.name}</li>
                <li><strong>Purchased By:</strong> {order?.customer ? order?.customer : order.company?.name}</li>
              </ul>
              <Button color="link" className="view-certificate-btn" onClick={handleCertificate}>View certificate</Button>
            </Col>
          </Row>

          <Row className="fulfilled-section mt-5">
            <Col>
              <h2 className="fulfilled-title">Fulfilled With</h2>
              <div className="fulfilled-projects-container">
                {order.projectRecords?.map((projectRecord, index) => (
                  <div key={index} className="fulfilled-project">
                    <h4 className="project-name">{projectRecord.projectId?.name}</h4>
                    <p className="project-type">{projectRecord.projectCategoryId?.name}</p>
                    <ul className="project-details">
                      <li><FontAwesomeIcon icon={faWeightScale} /> {(projectRecord.delta).toFixed(2)} kg</li>
                      <li><i className="bi bi-building"></i> {projectRecord?.projectId?.location}</li>
                      <li><i className="bi bi-check-circle"></i> {projectRecord?.projectId?.carbonRegistry?.name} ID: <a href='https://registry.verra.org/app/projectDetail/VCS/2250'>{projectRecord?.projectId?.carbonRegistry.id}</a></li>
                    </ul>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default PublicOrderPage;
