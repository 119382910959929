import React from 'react';
import { Link } from "react-router-dom";
import NavbarSubpageAR from "../../../Layout/NavbarSubpageAR";
import { Container } from 'reactstrap';

const TermsAR = () => {
    return (
        <React.Fragment>
            <NavbarSubpageAR />
            <section className="bg-home5" id="home" dir="rtl" style={{ textAlign: "right" }}>
                <div className="bg-overlay"></div>
                <Container>
                    <div
                        className="position-relative"
                        style={{
                            zIndex: "1",
                            backgroundColor: "#f8f8f8",
                            padding: "40px",
                            borderRadius: "8px",
                        }}
                    >
                        <h1 className="text-center mb-4">شروط الخدمة</h1>
                        <p className="text-center text-muted mb-5">سارية اعتبارًا من 20 يونيو 2024</p>
                        <p className="text mb-4">
                            انظر أيضًا <Link to="/ar/privacy">سياسة الخصوصية</Link>
                        </p>
                        <p>
                            يرجى قراءة شروط الخدمة هذه ("الشروط") بعناية قبل استخدام الخدمات المقدمة من قبل c-mass Inc. ("c-mass"). بإنشاء حساب على c-mass.com أو بتنفيذ طلب أو أكثر بالإشارة إلى هذه الشروط، فإنك ("العميل") توافق على الالتزام بهذه الشروط.
                        </p>

                            <h3>1. سياسة الإرجاع والاسترداد</h3>
                            <p>
                                في c-mass، نلتزم بضمان رضا عملائنا عن خدماتنا ومنتجاتنا. توضح هذه السياسة الشروط التي يمكن بموجبها طلب الإرجاع والاسترداد.
                            </p>
                            <h4>- الأهلية لاسترداد الأموال</h4>
                            <ul>
                                <li>تتوفر استردادات الأموال للمنتجات والخدمات التي تم شراؤها مباشرة من c-mass.co.</li>
                                <li>لكي تكون مؤهلاً للاسترداد، يجب تقديم الطلب خلال 14 يومًا من تاريخ الشراء.</li>
                                <li>تنطبق الاستردادات فقط إذا:
                                    <ul>
                                        <li>لم يتم تسليم المنتج أو الخدمة كما هو موضح.</li>
                                        <li>وجود مشاكل تقنية لم يتمكن فريق الدعم من حلها.</li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>- عملية الاسترداد</h4>
                            <ul>
                                <li>يجب على العملاء تقديم طلب استرداد عبر البريد الإلكتروني على العنوان: abdulrahman@c-mass.co، مع تضمين:
                                    <ul>
                                        <li>إثبات الشراء (مثل الإيصال أو تأكيد الطلب).</li>
                                        <li>وصف تفصيلي للمشكلة أو سبب طلب الاسترداد.</li>
                                    </ul>
                                </li>
                                <li>سيتم معالجة الاستردادات خلال 14 يوم عمل من الموافقة على الطلب.</li>
                                <li>سيتم رد الأموال إلى طريقة الدفع الأصلية.</li>
                            </ul>
                            <h4>- العناصر غير القابلة للاسترداد</h4>
                            <ul>
                                <li>عمليات التكامل المخصصة أو الخدمات المصممة التي تم تقديمها بالفعل.</li>
                                <li>المنتجات أو الخدمات التي تم شراؤها من خلال مزودي الطرف الثالث.</li>
                            </ul>
                            <h4>- التعديلات والإلغاء</h4>
                            <ul>
                                <li>يمكن للعملاء طلب تعديل أو إلغاء طلبهم خلال 24 ساعة من الشراء لاسترداد كامل المبلغ.</li>
                                <li>لا يمكن إلغاء الطلبات التي تمت معالجتها وتسليمها ولكن قد تكون مؤهلة للدعم الفني أو التعديلات.</li>
                            </ul>
                            <h4>- ضمان الجودة</h4>
                            <ul>
                                <li>في حال وجود أي مشكلات تتعلق بجودة المنتج أو أدائه، يمكن للعملاء التواصل معنا للحصول على حلول، بما في ذلك الإصلاح أو الاستبدال أو الاسترداد.</li>
                            </ul>
                            <h4>- التواصل معنا</h4>
                            <ul>
                                <li>البريد الإلكتروني: abdulrahman@c-mass.co</li>
                                <li>الهاتف: +966 53 400 6112</li>
                            </ul>
                            <p>
                                تحتفظ c-mass بالحق في تعديل هذه السياسة حسب الضرورة لضمان أفضل تجربة لعملائنا.
                            </p>


                        <p>
                            <h3>2. نماذج الطلبات والوصول إلى الخدمة</h3>
                            بعد التنفيذ المتبادل، يتم دمج كل نموذج طلب في الاتفاقية. تتيح c-mass للعميل استخدام المنتجات أو الخدمات المذكورة في نموذج الطلب وفقًا للشروط المحددة فيه.
                        </p>

                        <p>
                            <h3>3. تعويضات الكربون</h3>
                            قد تتيح الخدمة للعميل شراء كميات معينة من تعويضات الكربون. تُنفذ هذه المعاملات وفقًا للوائح السجلات والممارسات المتاحة.
                        </p>

                        <p>
                            <h3>4. التنفيذ</h3>
                            تقدم c-mass مساعدة قياسية لتنفيذ الخدمة مقابل الرسوم المنصوص عليها في نموذج الطلب. إذا تجاوزت المساعدة الساعات المتفق عليها، سيتم احتساب رسوم إضافية.
                        </p>

                        <p>
                            <h3>5. الدعم ومستويات الخدمة</h3>
                            تقدم c-mass الدعم وتوفر الخدمة بشكل معقول شريطة دفع جميع الرسوم.
                        </p>

                        <p>
                            <h3>6. تحديثات الخدمة</h3>
                            تقدم c-mass تحديثات وتحسينات للخدمة دون تكلفة إضافية، وتحتفظ بالحق في إجراء تغييرات كبيرة مع إشعار مسبق.
                        </p>

                        <p>
                            <h3>7. الملكية</h3>
                            تحتفظ c-mass بجميع حقوق الملكية الفكرية المتعلقة بالخدمات. أي تعليقات أو اقتراحات مقدمة إلى c-mass تُعتبر مرخصة لاستخدامها لأي غرض.
                        </p>

                        <p>
                            <h3>8. الرسوم والدفع</h3>
                            يجب على العميل دفع الرسوم المذكورة في نموذج الطلب. الرسوم المدفوعة غير قابلة للاسترداد.
                        </p>

                        <p>
                            <h3>9. القيود</h3>
                            لا يجوز للعميل عكس هندسة الخدمات أو تعديلها أو استخدامها لصالح طرف ثالث.
                        </p>

                        <p>
                            <h3>10. بيانات العميل</h3>
                            يحتفظ العميل بجميع حقوق الملكية لبياناته، ويكون مسؤولاً عن دقتها وسلامتها.
                        </p>

                        <p>
                            <h3>11. التكامل مع أطراف ثالثة</h3>
                            قد تعتمد الخدمة على تكاملات مع أطراف ثالثة، ويجب على العميل الامتثال لشروط هذه الأطراف.
                        </p>

                        <p>
                            <h3>12. المدة وإنهاء العقد</h3>
                            تستمر الاتفاقية حتى انتهاء جميع شروط نموذج الطلب. يمكن إنهاء الاتفاقية في حالة الإخلال الجوهري بالشروط.
                        </p>

                        <p>
                            <h3>13. التعويض</h3>
                            يتحمل كل طرف مسؤولية تعويض الطرف الآخر عن أي مطالبات أو خسائر ناجمة عن انتهاكات.
                        </p>

                        <p>
                            <h3>14. إخلاء المسؤولية</h3>
                            تُقدم الخدمة "كما هي" دون أي ضمانات، بما في ذلك ضمانات القابلية للتسويق.
                        </p>

                        <p>
                            <h3>15. تحديد المسؤولية</h3>
                            لا يتحمل أي طرف مسؤولية الأضرار غير المباشرة أو العقابية.
                        </p>

                        <p>
                            <h3>16. أحكام عامة</h3>
                            تُحكم الاتفاقية وفقًا لقوانين المملكة العربية السعودية. يجب إرسال الإشعارات كتابيًا للطرف الآخر.
                        </p>
                    </div>
                </Container>
            </section>
        </React.Fragment>

    );
};

export default TermsAR;
