// PaymentComponent.js
import React, { useEffect } from 'react';

const PaymentComponent = ({ amount, companyId, protfolio }) => {
    useEffect(() => {
        if (window.Moyasar) {
            window.Moyasar.init({
                element: '.mysr-form',
                amount: amount * 100,
                currency: 'SAR',
                description: `${companyId} ${amount} ${protfolio}`,
                publishable_api_key: process.env.REACT_APP_MOYASAR_API_KEY,
                callback_url: `${window.location.origin}/profile/payment-callback`,
                methods: ['creditcard', 'mada'],
                on_completed: (payment) => {
                }
            });
        }
    }, [amount, protfolio]);

    return <div className="mysr-form"></div>;
};

export default PaymentComponent;
