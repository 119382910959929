import React from 'react';
import { Link } from 'react-router-dom';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { Col, Container, Row, Button } from "reactstrap";

const WelcomePage = () => {
  return (
    <React.Fragment>
      <NavbarSubpage/>
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="welcome-page">
                  <h1>Welcome to the Business Carbon Calculator</h1>
                  <p>
                    We are happy to partner with you on measuring your company’s CO2 emissions.
                    Unlike carbon emissions, the more you put into c-mass, the more you get out.
                    To show you the most comprehensive information about your emissions and how you
                    benchmark against other companies your size, we need get to know you first.
                  </p>
                  <div className="mt-4">
                    <Link to="/quiz/company-name" className="btn btn-primary mt-2">
                    Start Quiz
                    </Link>
                    
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WelcomePage;
