import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpage from "../../Layout/NavbarSubpage";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";

const CompanySectorPage = () => {
  const [companySector, setCompanySector] = useState('');
  const { updateQuizData } = useContext(QuizContext);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (companySector) {
      updateQuizData({ companySector });
      navigate('/quiz/section-overview');
    }
  };

  const handleSectorChange = (selectedOption) => {
    setSelectedSector(selectedOption);
    setCompanySector(selectedOption.value);
  };

  useEffect(() => {
    async function fetchSectors() {
      try {
        const response = await http.get(API_ENDPOINTS.SECTORS);
        const sectorOptions = response.data.sectors.map(sector => ({
          value: sector.name,
          label: sector.name,
        }));
        setSectors(sectorOptions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sectors:", error);
      }
    }

    fetchSectors();
  }, []);

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div className="position-relative" style={{ zIndex: "1" }}>
              <Row className="align-items-center">
                <Col lg={6}>
                  <h2>What sector do you operate in?</h2>
                  <Select
                    value={selectedSector}
                    onChange={handleSectorChange}
                    options={sectors}
                    placeholder="Select Sector"
                    isSearchable={true}
                    menuPlacement="auto"
                    styles={{
                      menu: provided => ({
                        ...provided,
                        maxHeight: '200px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                  <button onClick={handleNextClick} disabled={!companySector} className="btn btn-primary mt-2">
                    Next
                  </button>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanySectorPage;
