import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const PricingDataAR = [
  {
    title: "أساسي",
    price: "80 ريال سعودي",
    subtitle: "80 ريال سعودي / طن مكافئ ثاني أكسيد الكربون",
    isChildItem: [
      { id: 1, subItem: "لوحة القيادة والطلبات عبر الويب" },
      { id: 2, subItem: "محفظة تعويض متنوعة" },
      { id: 4, subItem: "تقديرات قياسية مجانية" },
      { id: 5, subItem: "دعم فني" },
    ],
    buttonLabel: "ابدأ الآن",
  },
  {
    title: "مخصص للمؤسسات",
    price: "مخصص",
    isChildItem: [
      { id: 1, subItem: "تقديرات ونقاط نهاية إضافية" },
      { id: 2, subItem: "محفظة مخصصة" },
      { id: 3, subItem: "معايير اختيار المشروع المخصصة" },
      { id: 4, subItem: "دعم على مدار الساعة طوال أيام الأسبوع" },
      { id: 5, subItem: "تسعير حسب الحجم" },
    ],
    buttonLabel: "لنتحدث",
  },
];

const PricingAR = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="pricing" dir="rtl" style={{ textAlign: "right" }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-4">
                <p className="text-uppercase text-muted mb-2">تسعير بسيط وشفاف</p>
                <h3>يمنحك تسعيرنا حسب الاستخدام سعراً عادلاً لما تستخدمه فقط.</h3>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            {PricingDataAR.map((items, key) => (
              <Col key={key} lg={6} md={8}>
                <div className="pricing-box bg-white box-shadow text-center p-5 mt-5 rounded border">
                  <p className="price-title mb-4 pb-3">{items.title}</p>
                  <div className="price-section mb-4">
                    <h1 className="mb-1 price">{items.price}</h1>
                    {items.subtitle && <p className="subtitle">{items.subtitle}</p>}
                  </div>
                  <div className="pricing-features mt-4 pt-4">
                    {items.isChildItem.map((item, idx) => (
                      <p key={idx}>{item.subItem}</p>
                    ))}
                  </div>
                  <div className="mt-5">
                    <Link
                      to={items.price === "مخصص" ? "#contact" : "/login"}
                      className="btn btn-outline-primary w-100"
                      style={{ borderRadius: "25px" }}
                    >
                      {items.buttonLabel}
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingAR;

