// PaymentCallback.js
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner, Container, Row, Col, Alert } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../contexts/mode/ModeContext';
import OrderContext from '../../contexts/order/OrderContext';

const PaymentCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isSandboxMode } = useContext(ModeContext);
    const { orderData, clearOrderData } = useContext(OrderContext);
    const [statusMessage, setStatusMessage] = useState("Processing your payment...");
    const [isLoading, setIsLoading] = useState(true);

    const query = new URLSearchParams(location.search);
    const paymentId = query.get('id');
    const status = query.get('status');

    useEffect(() => {
        if (!orderData || !status || !paymentId) {
            console.log("Waiting for required data...");
            return; // Exit early if data isn't available yet
        }
        
        const placeOrderWithPaymentId = async () => {
            if (status === 'paid' && paymentId) {
                try {
                    const finalOrderData = {
                        ...orderData,
                        portfolioId: orderData.portfolio.id,
                        paymentId: paymentId,
                        status
                    };

                    const response = await http.post(`${API_ENDPOINTS.SUBMIT_ORDER}?isSandbox=${isSandboxMode}`, finalOrderData);

                    setStatusMessage("Order placed successfully. Redirecting...");
                    clearOrderData();
                    setIsLoading(false);
                    setTimeout(() => navigate(`/profile/order/success/${response.data.orderId}`), 3000);

                } catch (error) {
                    console.error('Error placing order:', error);
                    setStatusMessage("Error placing order. Redirecting...");
                    setIsLoading(false);
                    setTimeout(() => navigate('/profile/offset'), 3000);
                }
            } else {
                console.error('Payment failed or was not completed');
                setStatusMessage("Payment failed. Redirecting...");
                setIsLoading(false);
                setTimeout(() => navigate('/profile/offset'), 3000);
            }
        };

        placeOrderWithPaymentId();
    }, [status, paymentId, navigate, isSandboxMode, orderData, clearOrderData]);

    return (
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', textAlign: 'center' }}>
            <Row>
                <Col>
                    <div className="text-center">
                        {isLoading && <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />}
                        <Alert color={status === 'paid' ? 'success' : 'danger'} className="mt-4">
                            <h4>{statusMessage}</h4>
                        </Alert>
                        <p>Please wait while we process your request...</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PaymentCallback;
