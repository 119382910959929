import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import '../../assets/css/BillingPage.css';
import UpdatePaymentComponent from "../../components/UpdatePaymentComponent";

const BillingPage = () => {
  const [billingInfo, setBillingInfo] = useState(null);
  const [loadingBilling, setLoadingBilling] = useState(true);
  const [loadingPayments, setLoadingPayments] = useState(true);
  const [allPaymentsInfo, setAllPaymentsInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const billingResponse = await http.get(`${API_ENDPOINTS.GET_PAYMENT_INFO}`);
        setBillingInfo(billingResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBillingInfo(null);
      } finally {
        setLoadingBilling(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const paymentsResponse = await http.get(`${API_ENDPOINTS.GET_ALL_PAYMENT_INFO}`);
        setAllPaymentsInfo(paymentsResponse.data.orders);
      } catch (error) {
        console.error("Error fetching data:", error);
        setAllPaymentsInfo([]);
      } finally {
        setLoadingPayments(false);
      }
    };
    fetchData();
  }, []);

  const getPaymentStatusBadgeClass = (ps) => {
    switch (ps?.toLowerCase()) {
      case 'paid':
        return 'bg-success'; // Green badge for paid
      default:
        return 'bg-danger'; // Red badge if status is unknown
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Container className="dashboard">
      <>
        {/* Section 1: Payment Method */}
        {loadingBilling ? (
          <Spinner />
        ) : (
          <section className="billing-section">
            <h2>Billing</h2>
            <div className="billing-card">
              <h4>Payment Method</h4>

              {billingInfo ? (
                <>
                  <p>Your charges will be deducted from this card.</p>
                  <Table borderless>
                    <thead>
                      <tr>
                        <th>BRAND</th>
                        <th>NUMBER</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{billingInfo?.cardCompany}</td>
                        <td>{billingInfo?.cardNumber}</td>
                        <td>
                          <Button
                            className="btn-change"
                            style={{ borderRadius: '25px', backgroundColor: 'black', padding: '5px 10px' }}
                            onClick={toggleModal}
                          >
                            Change
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                <p>No payment method added.</p>
              )}
            </div>
          </section>
        )}

        {/* Section 3: Payments */}
        {loadingPayments ? (
          <Spinner />
        ) : (
          <section className="billing-card">
            <h4>Payments</h4>
            <p>
              This includes payments for API usage in prior periods, and outstanding, pending, and processed payments
              for orders placed directly in the web dashboard.
            </p>
            {allPaymentsInfo.length > 0 ? (
              <Table borderless>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TYPE</th>
                    <th>CREDITS PURCHASED</th>
                    <th>AMOUNT</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {allPaymentsInfo.map((payment) => (
                    <tr key={payment?._id}>
                      <td>
                        {payment?.createdOn?.split('T')[0]} <i className="mdi mdi-open-in-new"></i>
                      </td>
                      <td>{payment?.via}</td>
                      <td>{payment?.creditsPurchased} kg</td>
                      <td>SAR {payment?.price_sar_halalas / 100}</td>
                      <td className="status-paid">
                        <span className={`badge ${getPaymentStatusBadgeClass(payment.paymentId?.status)}`}>
                          {payment.paymentId?.status ? payment.paymentId?.status : 'na'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No orders</p>
            )}
          </section>
        )}

        {/* Modal for Change Payment */}
        <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>Change Payment Method</ModalHeader>
          <ModalBody>
            <UpdatePaymentComponent companyId={billingInfo?.companyId} />
          </ModalBody>
        </Modal>
      </>
    </Container>
  );
};

export default BillingPage;
