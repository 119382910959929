import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import img1 from "../assets/images/users/img-1.png";
import img3 from "../assets/images/users/img-3.jpg";

const TeamCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={4} md={6} className="mb-4 d-flex justify-content-center">
        <div className="team-box text-center">
          <div className="team-img position-relative">
            <img src={props.imgsrc} className="img-fluid rounded-circle" alt="" />
            <div className="team-content">
              <ul className="list-inline mb-0 p-0">
                {props.isIcon.map((item, key) => (
                  <li key={key} className="list-inline-item">
                    <Link to={item.link}>
                      <i className={item.icon}></i>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="p-3">
            <Link to="/#" className="text-primary">
              <h5 className="fs-18 mb-1">{props.name}</h5>
            </Link>
            <p className="mb-0">{props.designation}</p>
          </div>
        </div>
        {/* <!--end team-box--> */}
      </Col>
    </React.Fragment>
  );
};

const TeamData = [
  {
    imgsrc: img1,
    name: "Hamoud Al-Subaie",
    designation: "Co-Founder & CEO",
    isIcon: [
      // { icon: "mdi mdi-facebook" },
      // { icon: "mdi mdi-instagram" },
      { icon: "mdi mdi-linkedin", link: 'https://www.linkedin.com/in/hamoud-alsubaie-b48627118/' },
    ],
  },
  {
    imgsrc: img3,
    name: "Abdulrahman Alhumidan",
    designation: "Co-Founder & CTO",
    isIcon: [
      // { icon: "mdi mdi-facebook" },
      // { icon: "mdi mdi-instagram" },
      { icon: "mdi mdi-linkedin", link: 'https://www.linkedin.com/in/abdulrahman-alhumidan-5b4591125/' },
    ],
  },
];

const Team = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="team">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">Meet Our Team</p>
                <div className="title-border mt-3"></div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4 justify-content-center">
            {/* Team Detail Component Start */}
            {TeamData.map((value, idx) => (
              <TeamCard
                key={idx}
                imgsrc={value.imgsrc}
                name={value.name}
                designation={value.designation}
                isIcon={value.isIcon}
              />
            ))}
            {/* Team Detail Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Team;
